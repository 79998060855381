import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TemplateService } from 'src/app/services/template.service';
import { AppService } from 'src/app/services/app.service';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Template } from 'src/app/models/template.model';
import { Campaign } from 'src/app/models/campaign.model';
import { CampaignService } from 'src/app/services/campaign.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { CharitiesService, Charity } from 'src/app/services/charities.service';
import { CreateCardComponent } from '../create-card/create-card.component';
import { FileUploaderComponent } from 'src/app/modules/shareModule/components/file-uploader/file-uploader.component';
import { PayoutComponent } from 'src/app/modules/shareModule/dialogs/payout/payout.component';
import { ImageCropperComponent } from 'src/app/modules/shareModule/components/image-cropper/image-cropper.component';
import { Category } from 'src/app/models/category.model';
import { CategoriesService } from 'src/app/services/categories.service';
import { CreateCatalogueProductAndProceedComponent } from '../create-catalogue-product-and-proceed/create-catalogue-product-and-proceed.component';
import { ProdigiFormComponent } from '../prodigi-form/prodigi-form.component';
import { SelectProdigiProductComponent } from '../select-prodigi-product/select-prodigi-product.component';
import { CollageMakerDialogComponent } from '../../dialogs/collage-maker-dialog/collage-maker-dialog.component';
import { DonateCharityDialogComponent } from '../../dialogs/donate-charity-dialog/donate-charity-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { SuccessMessageDialogComponent } from 'src/app/modules/shareModule/dialogs/success-message-dialog/success-message-dialog.component';
import { CWishesComponent } from 'src/app/modules/detail/c-wishes/c-wishes.component';
import { Contribution } from 'src/app/models/contribution';
import * as moment from 'moment';
import { CharityDialogComponent } from '../../dialogs/charity-dialog/charity-dialog.component';
import { PayoutDialogComponent } from './payout-dialog/payout-dialog.component';
import { DonateCharityDialogOwnGiftComponent } from '../../dialogs/donate-charity-dialog-owngift/donate-charity-dialog-own-gift.component';
import { ValidationService } from '../../../shareModule/controls-msg/validation.service';
const AMOUNT_PATTERN = /^((^((?!(0))[0-9]*)$)|((0)?([1-9]*)\.[0-9]*)?)?$/i;

@Component({
  selector: 'app-buy-gift-card',
  templateUrl: './buy-gift-card.component.html',
  styleUrls: ['./buy-gift-card.component.scss'],
})
export class BuyGiftCardComponent implements OnInit, OnChanges {
  currentDate = new Date();
  currentTime: any;
  selectedDate: any;
  maxTime: any;
  giftCardForm: FormGroup;
  @Input() form: FormGroup = new FormGroup({});
  @Input() loading: boolean = false;
  @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();
  @Input() campaign: Campaign = new Campaign();
  @Output() campaignChange: EventEmitter<Campaign> = new EventEmitter();
  templates: Page<Template>;
  date: Date = new Date();
  sendVoucerToMySelft: any;
  disable: boolean = false;
  get fromValue(): Campaign {
    return this.form.value;
  }

  selectedCategoryPrice: number;

  get cardPrice() {
    return (
      this.selectedCategoryPrice ||
      this.configurationService?.list?.selected?.cardPrice ||
      0
    );
  }

  get campaignLink(): string {
    const pathname = new URL(this.campaign.inviteLink).pathname;
    return `${location.origin}${pathname}`;
  }

  charities: Page<Charity>;
  @ViewChild('prodigiForm') prodigiForm: ProdigiFormComponent;
  @ViewChild('prdigiSelection') prdigiSelection: SelectProdigiProductComponent;

  categories: Page<Category> = new Page({
    api: this._categoryService.categories,
    properties: new Category(),
    serverPaging: false,
  });

  campaignId?: string = '';
  sendOrSchedule: boolean = false;
  sendVoucherToMySelf: boolean = false;

  constructor(
    private _templateService: TemplateService,
    public _appService: AppService,
    private _campaignService: CampaignService,
    private _router: Router,
    private _configurationsService: ConfigurationsService,
    private _charitiesService: CharitiesService,
    private _categoryService: CategoriesService,
    private _activatedRoute: ActivatedRoute,
    private _matDialog: MatDialog,
    public configurationService: ConfigurationService,
    private fb: FormBuilder
  ) {
    this.campaignCardPr = 4;
    this.form.get('cardAmount')?.setValue(4);
    this.campaignId = this._activatedRoute.snapshot.queryParams['campaignId'];
    this.categories.fetch();
    this.charities = new Page({
      api: _charitiesService.charities,
      serverPaging: false,
    });
    this.charities.fetch().then((d) => {
      d.items.forEach((res) => {
        if (res?.status == 'active') {
          this.form.get('charityId').setValue(res?.id);
        }
      });
    });
    this.templates = new Page({
      api: _templateService.templates,
      properties: new Template(),
      filters: [
        {
          field: 'categoryId',
          value: null,
        },
      ],
    });
    this.fetchTemplate();
    this.form.get('payoutType')?.valueChanges.subscribe((k: any) => {
      this.form.get('giftAmount')?.setValue(0);
    });
    this.giftCardForm = this.fb.group({
      selfSendGiftCard: [false], // Define the control here
    });
  }
  price: any;

  async fetchTemplate() {
    try {
      let res = await this.templates.fetch();
      res.items.map((d) => {
        this.price = d.price;
      });
      const categoryId = this.templates.filters.properties['categoryId'].value;
      if (!categoryId) return;
      for (const item of this.categories.items) {
        if (+item.id == +categoryId) {
          this.selectedCategoryPrice = item.price;
          break;
        }
      }
    } catch (error) {}
  }

  public get campaignContent() {
    return this.configurationService?.campaignContent || {};
  }

  isPreviewFirstTime = true;

  get isAlignWishes() {
    return this.form.get('selectedTemplateUrl')?.value;
    // && this.campaign?.contributions?.filter((i) => i.message)?.length
  }

  async ngOnInit() {
    this.templates.filters.properties['categoryId'].value =
      this.form.get('categoryId').value;
    await this.templates.fetch().then((d) => {
      this.onSelectTemplate(false, this.campaign.selectedTemplateUrl);
    });

    if (!this.campaign.isAmountProcessed) {
      this.form.get('giftLink').enable();
      if (this.campaign.giftLink)
        this.form.get('giftLink').setValue(this.campaign.giftLink);
    }

    this.form.get('decidedGift').valueChanges.subscribe((d) => {
      if (d && this.form.get('giftDecisionType').value !== 'decided') {
        this.form.get('giftName').enable();
      } else {
        this.form.get('giftName').disable();
      }
    });
  }

  onEmailTypeChange(event: any) {
    if (!event.value) {
      this.form.get('cardRecipientEmail').reset();
      this.form.get('cardSendEmailDate').reset();
      this.form.get('cardSendEmailTime').reset();
      this.form.get('confirmEmail').reset();
    }
    this.sendVoucherToMySelf = event.value;
  }

  onEmailType(event: any) {
    if (!event.value) {
      this.form.get('cardRecipientEmail').reset();
      this.form.get('cardSendEmailDate').reset();
      this.form.get('cardSendEmailTime').reset();
      this.form.get('confirmEmail').reset();
    }
  }

  onChange(event: any) {
    if (!event.value) {
      this.form.get('confirmEmail')?.value;
    }
  }

  onDateChange(event: any): void {
    this.selectedDate = new Date(event.target.value);

    const currentDateFormatted = new Date(this.currentDate);
    this.form.get('cardSendEmailTime')?.setValue(null);
    if (moment(this.selectedDate).isAfter(currentDateFormatted)) {
      this.currentTime = moment(this.selectedDate).format('hh:mm a');
      return;
    }
    this.currentTime = moment(this.currentDate).format('hh:mm a');
    // .add(30, 'minutes')
  }

  ngOnChanges(c: SimpleChanges) {
    if (c['campaign'] && this.campaign.id) {
      if (this.campaign.giftDecisionType != 'decided') {
        this.resetControl('giftName', true);
      }
    }

    this.form.get('payoutType')?.setValue('catalogue');
  }

  copyCardLink() {
    this._appService.copyLink(
      `${location.host}/digital-e-card/${this.campaignId}`
    );
  }

  onDecidedGiftChange(v: boolean) {
    if (v) {
      [
        'wantToDonate',
        'charityAmountType',
        'charityAmount',
        'receipientEmail',
      ].forEach((i) => this.resetControl(i, true));
      //On click Choose gift from gift catalogue YES option
      //Starts
      ['giftAmount', 'wantToDonate', 'receipientEmail'].forEach((i) =>
        this.resetControl(i, true)
      );
      //Ends
      this.form.get('wantToDonate').setValue(false);
      if (this.form.get('giftDecisionType').value !== 'decided') {
        this.resetControl('giftName', true);
      }
    } else {
      ['giftAmount', 'wantToDonate', 'receipientEmail'].forEach((i) =>
        this.resetControl(i, true)
      );
      this.form.get('wantToDonate').setValue(false);
      this.form.get('giftName').disable();
    }
  }

  onCharityAmountTypeChange(type: 'autoFilled' | 'other') {
    if (type == 'autoFilled') {
      this.form.get('charityAmount').setValue(this.remainingAmount); // new add
    } else if (this.form.get('charityAmount').value === this.remainingAmount) {
      this.resetControl('charityAmount', true);
    }
    this.form.get('charityAmount').setValidators([
      Validators.required,
      Validators.min(1),
      Validators.max(this.remainingAmount - this.campaignCardPr), // new add
    ]);
  }

  getCurrentTimeDate() {
    const currentTimeValue = this.form.get('cardSendEmailTime')?.value;
  }

  onWantToDonate(v: boolean) {
    ['charityAmountType', 'charityAmount', 'receipientEmail'].forEach((i) =>
      this.resetControl(i, true)
    );
    if (v) {
      this.form.get('charityAmountType').setValue('autoFilled');
      this.onCharityAmountTypeChange('autoFilled');
    } else {
      this.resetControl('charityAmount', false);
      this.form.get('charityAmount').clearValidators();
    }
  }

  openWishes(contributions: Contribution[]) {
    this._matDialog.open(CWishesComponent, {
      data: { contributions: contributions, type: 'downloadWishes' },
      width: '600px',
    });
  }

  public dataImg = '';

  cardVal: any;
  onTemplateTypeChange(value: string) {
    this.form.get('templateType').valueChanges.subscribe((t) => {
      this.cardVal = t;
    });
    if (value == 'freeCard') {
      this.campaignCardPr = 0;
    }
    if (value == 'uploaded') {
      this.campaignCardPr = 4;
    }

    this.form.get('cardAmount').setValue(this.campaignCardPr);
    if (value == 'selected') {
      this.form.get('selectedTemplateUrl')?.setValue('');
    }
    if (value == 'uploaded') {
      this.form.get('selectedTemplateUrl')?.setValue(this.dataImg);
    }
  }

  openWishesCard(contributions: Contribution[]) {
    this._matDialog.open(CWishesComponent, {
      width: '600px',
      data: { contributions: contributions, type: 'downloadWishes' },
    });
  }

  campaignCardPr?: any;
  onSelectTemplate(isCustom: boolean, url, price?: any) {
    this.campaignCardPr = price;
    this.form.get('selectedTemplateUrl').setValue(url);
    if (this.campaign.collectedAmount)
      this.form
        .get('giftAmount')
        .setValidators([
          Validators.pattern(AMOUNT_PATTERN),
          Validators.max(this.campaign.collectedAmount - this.campaignCardPr),
        ]);
  }

  get isExpried(): boolean {
    let expireOn: Date = new Date(this.form.get('collectionDeadline').value);
    if ((expireOn.getTime() - this.date.getTime()) / (1000 * 3600 * 24) < 0) {
      return true;
    } else {
      return false;
    }
  }

  createCollage() {
    const dialogRef = this._matDialog.open(CollageMakerDialogComponent, {
      height: '100vh',
      maxHeight: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      autoFocus: false,
      disableClose: true,
      panelClass: 'create-card-dialog',
      data: {
        campaign: { ...this.campaign, ...this.form.value },
        productDetail:
          this.form.value?.cardType == 'prodigi' &&
          this.prdigiSelection?.selected
            ? this.prdigiSelection?.selected
            : null,
      },
    });
    dialogRef.afterClosed().subscribe((file: File) => {
      if (file) {
        this.uploadFile(file);
      }
    });
  }

  cropCustomTemplate(event) {
    let dialog = this._appService.matDialog.open(ImageCropperComponent, {
      data: { event: event, ratio: 130 / 155 },
      disableClose: true,
      minWidth: '500px',
      maxWidth: '100%',
      maxHeight: '100%',
    });
    dialog.afterClosed().subscribe((file: File) => {
      if (file) {
        this.uploadFile(file);
      }
    });
  }

  uploadFile(file: File) {
    this._appService.matDialog
      .open(FileUploaderComponent, {
        data: [file],
        disableClose: true,
        minWidth: '400px',
        maxWidth: '100%',
        maxHeight: '100%',
      })
      .afterClosed()
      .subscribe((urls: string[]) => {
        if (urls.length && urls[0].length) {
          this.onSelectTemplate(true, urls[0]);
          this.dataImg = urls[0];
        }
      });
  }

  saveAndContinue(viewOnly: boolean, dialogData?: any) {
    this._appService.matDialog
      .open(CreateCatalogueProductAndProceedComponent, {
        maxHeight: '100vh',
        maxWidth: '100vw',
        width: '90vw',
        height: '90vh',
        data: {
          data: dialogData?.giftAmount,
          productId: dialogData,
          remainingAmount: this.remainingAmount,
          isSingleToProceed: true,
          campaign: {
            ...this.campaign,
            ...this.form.value,
            cardAmount: this.campaignCardPr,
            remainingAmount: this.remainingAmount,
          },
          viewOnly,
          multiple: false,
          sendType: this.sendOrSchedule,
        },
      })
      .afterClosed()
      .subscribe((d) => {
        if (d?.multiple) {
          this.OpenCharityDialog({
            d,
          });
          return;
        }
        if (d) {
          this.campaign = { ...this.campaign, ...d };
          this.campaign.isAmountProcessed = true;
          this.campaignChange.emit(this.campaign);
          this.createProdigiOrder();
          Object.keys(this.form.controls).forEach((d) =>
            this.form.get(d).disable()
          );
          this.successDialog();
        }
      });
  }

  createProdigiOrder() {
    if (this.form.get('cardType').value === 'prodigi') {
      this.prodigiForm.createOrder();
    }
  }

  successDialog() {
    const sendToRecipient = `Thank you for redeeming your CollectaGift Pot for ${this.campaign.receipientName}. The selected Gift Voucher(s), together with the Greeting Card, will be emailed to the Recipient at the email address provided. If you scheduled send, they will be sent at the requested time, otherwise they will be sent straight away. Thank you for using CollectaGift and if you have any questions, please contact us on hello@collectagift.com.`;
    const sendToMySelf = `Thank you for redeeming your CollectaGift Pot for ${this.campaign.receipientName}. The selected Gift Voucher(s) will be emailed to your registered email address. The Greeting Card will be sent directly to the Recipient at the email address provided.  If you scheduled send, the Greeting Card will be sent at the requested time, otherwise it will be sent immediately. Thank you for using CollectaGift and if you have any questions, please contact us on hello@collectagift.com.`;
    const sendToMySelfManual = `Thank you for redeeming your CollectaGift Pot for ${this.campaign.receipientName}.  The selected Gift Voucher(s) will be emailed to your registered email address for you to send to the Recipient when you wish or to buy the Recipient a Gift using the voucher. The Greeting Card will be also be sent to your registered email address and you can forward it onto the Recipient whenever you wish.`;

    this._matDialog
      .open(SuccessMessageDialogComponent, {
        data: {
          title: this.form.get('sendToRecipientEmail').value
            ? sendToRecipient
            : this.form.get('isAutoEmailToRecipient').value
            ? sendToMySelf
            : sendToMySelfManual,
        },
      })
      .afterClosed()
      .subscribe((d) => {
        this._router.navigate(['/pages/detail/campaigns']);
      });
  }

  openCharityDialog(charityType?: string) {
    this.cardVal = this.form.get('templateType')?.value;
    // if (!this.form.get('automaticCardEmail')?.value) {
    //   return this._appService.snackBarOpen(
    //     'Please ensure you select an option under question 2.'
    //   );
    // }
    const finaly = (data?: any) => {
      // if (
      //   this.form.controls.payoutType.value == 'catalogue'
      // ) {
      this.saveAndContinue(false, data);
      // }
      // else {
      //   // this.loading = true;
      //   // this.update(true);
      // }
    };
    if (this.remainingAmount <= 0) return finaly();
    if (
      this.form.controls.payoutType.value == 'account' &&
      this.form.get('decidedGift').value &&
      !this.form.get('giftAmount')?.value
    ) {
      this._appService.snackBarOpen('Please fill How much does/did this cost?');
      return;
    }
    const dialogRef = this._matDialog.open(DonateCharityDialogComponent, {
      disableClose: true,
      data: {
        this: this,
        cardVal: this.cardVal,
        payoutType: this.form.get('payoutType')?.value,
        charityType: charityType,
        sendType: this.sendOrSchedule,
      },
      panelClass: 'stripe-fee-detail',
      maxHeight: '100vh',
      maxWidth: '100vw',
      minWidth: '400px',
    });

    dialogRef.afterClosed().subscribe((d: any) => {
      if (!d) {
        return;
      }
      if (d.saveToProceedDialog) {
        finaly(d);
        return;
      }
      if (d.isOpenCharityDialog) {
        this.OpenCharityDialog(d.data, d.donationType);
      }
    });
  }

  OpenCharityDialog(data, donationType?: boolean) {
    return this._matDialog.open(CharityDialogComponent, {
      panelClass: 'charity-dialog',
      maxHeight: '600px',
      maxWidth: '600px',
      minWidth: '600px',
      height: 'auto',
      data: {
        data,
        campaign: {
          ...this.campaign,
          ...this.form.value,
          cardAmount: this.campaignCardPr,
          remainingAmount: this.remainingAmount,
        },
        isDonated: donationType,
      },
    });
  }

  openCharityDialogOwnGift(charityType?: string) {
    this.cardVal = this.form.get('templateType')?.value;

    if (!this.form.get('automaticCardEmail')?.value) {
    }

    const finaly = (data?: any) => {

      this.saveAndContinue(false, data);
    };
    if (this.remainingAmount <= 0) return finaly();
    if (
      this.form.controls.payoutType.value == 'account' &&
      this.form.get('decidedGift').value &&
      !this.form.get('giftAmount')?.value
    ) {
      this._appService.snackBarOpen('Please fill How much does/did this cost?');
      return;
    }
    const dialogRef = this._matDialog.open(
      DonateCharityDialogOwnGiftComponent,
      {
        disableClose: true,
        data: {
          campaign: { ...this.campaign, ...this.form.value },
          this: this,
          cardVal: this.cardVal,
          payoutType: this.form.get('payoutType')?.value,
          charityType: charityType,
          templateUrl: this.form.get('selectedTemplateUrl')?.value,
          receipientEmail: this.form.get('cardRecipientEmail').value,
          cardPrice: this.cardPrice,
          sendType: this.sendOrSchedule,
        },
        panelClass: 'stripe-fee-detail',
        maxHeight: '100vh',
        maxWidth: '100vw',
        minWidth: '400px',
      }
    );

    dialogRef.afterClosed().subscribe(async (d: any) => {
      if (d) {
        this.campaign = { ...this.campaign, ...d };
        this.campaignChange.emit(this.campaign);
      }
    });
  }

  async update(isPayout?: boolean) {
    let body: Campaign = { ...this.form.value };
    Object.keys(this.form.controls).forEach(
      (k) => (body[k] = this.form.controls[k].value)
    );
    // if (this.form.get('templateType')?.value == 'selected') {
    body.cardAmount = this.campaignCardPr;
    // }
    if (body.charityAmount) {
      body.charityAmount = body.charityAmount - Number(body.giftAmount);
    }
    delete body.giftSuggestions;
    this.loadingChange.emit(true);
    this.saveProdigiDetail();
    try {
      const res = await this._campaignService.campaigns.update(
        this.campaign.id,
        body
      );
      this.campaign = res;
      this.campaignChange.emit(res);
      this.loadingChange.emit(false);
      if (isPayout) return this.payout();
      this._appService.snackBarOpen('campaign successfully Updated');
    } catch (err) {
      this.loadingChange.emit(false);
      this._appService.snackBarOpen(err);
    }
  }

  payout() {
    let cntrls = this.form.controls;
    if (cntrls.decidedGift.value) {
      // if (!cntrls.giftAmount.value) {
      //   return this._appService.snackBarOpen(
      //     'Please fill How much does/did this cost?'
      //   );
      // }
    } else {
      if (cntrls.charityAmountType.value === 'other') {
        if (!cntrls.charityAmount.value) {
          return this._appService.snackBarOpen(
            'Please fill How much want to donate?'
          );
        }
      }
    }
    // if (
    //   cntrls.purchaseCard?.value &&
    //   !this.form.controls['finalTemplate'].value
    // ) {
    //   return this._appService.snackBarOpen('Please align wishes on card.');
    // }
    let dialog = this._appService.matDialog.open(PayoutComponent, {
      data: {
        ...this.form.value,
        ...this.campaign,
        cardAmount: this.campaignCardPr,
        remainingAmount: this.remainingAmount,
      },
    });
    dialog.afterClosed().subscribe(async (d) => {
      if (d) {
        this.campaign = { ...this.campaign, ...d };
        this.campaign.isAmountProcessed = true;
        this.campaignChange.emit(this.campaign);
        this.createProdigiOrder();
        Object.keys(this.form.controls).forEach((d) =>
          this.form.get(d).disable()
        );
        this.successDialog();
      }
    });
  }

  createCard(cardType?: string) {
    let dialog = this._appService.matDialog.open(CreateCardComponent, {
      height: '100vh',
      maxHeight: '100vh',
      width: '90vw',
      maxWidth: '90vw',
      panelClass: 'create-card-dialog',
      data: {
        campaign: { ...this.campaign, ...this.form.value },
        isPreviewFirstTime: this.isPreviewFirstTime,
        cardType: cardType,
        productDetail:
          this.form.value?.cardType == 'prodigi'
            ? this.prdigiSelection?.selected
            : null,
        id: this.campaignId,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (!result?.urls?.length) return;
      const { urls, cardDimensions } = result;
      const [finalTemplate, selectedTemplateUrl, prodigiImg] = urls;
      this.campaign.cardDimensions = cardDimensions;
      var body: Campaign = null;
      if (finalTemplate) {
        this.form.get('finalTemplate').setValue(finalTemplate);
        this.campaign.finalTemplate = finalTemplate;
        body = { finalTemplate, cardDimensions };
      }
      if (selectedTemplateUrl) {
        this.form.get('selectedTemplateUrl').setValue(selectedTemplateUrl);
        this.campaign.selectedTemplateUrl = selectedTemplateUrl;
        body = { ...body, selectedTemplateUrl, cardDimensions };
      }
      if (prodigiImg) {
        this.prodigiForm.form.get('imgUrl').setValue(prodigiImg);
        this.saveProdigiDetail();
      }
      if (body) {
        this._campaignService.campaigns.update(this.campaign.id, body);
      }
    });
  }

  get stripeCharges(): number {
    var stripeCharges = 0;
    if (!this.payoutAmount || this.payoutAmount < 100) {
      stripeCharges = 0;
    } else {
      stripeCharges = ((0.25 / 100) * this.payoutAmount || 0) + 2.1;
    }
    this.form.get('stripeCharges').setValue(stripeCharges);
    return stripeCharges;
  }

  get payoutAmount(): number {
    if (!this.campaign?.id) return 0;
    var {
      decidedGift,
      giftAmount,
      wantToDonate,
      charityAmount,
      userAmount,
      charityAmountType,
    } = this.form.value;
    giftAmount = parseFloat(giftAmount as any) || 0;
    userAmount = parseFloat(userAmount as any) || 0;
    // const remainingAmount = (collectedAmount - ((giftAmount || 0) + (cardAmount || 0)));
    if (wantToDonate) {
      if (charityAmountType == 'autoFilled' || decidedGift) {
        charityAmount = this.remainingAmount;

        userAmount = giftAmount;
      } else {
        userAmount = this.remainingAmount - charityAmount;
        giftAmount = this.remainingAmount - charityAmount;
      }
    } else {
      charityAmount = 0;
      userAmount = (giftAmount || 0) + this.remainingAmount;

      if (!giftAmount) giftAmount = userAmount;
    }
    return userAmount;
  }

  resetControl(name: string, enable?: boolean) {
    let c = this.form.get(name);
    c.reset();
    c.disable();
    if (enable) c.enable();
  }

  saveProdigiDetail() {
    if (this.form.get('cardType').value === 'prodigi') {
      this.prodigiForm.save();
    }
  }

  get remainingAmount() {
    if (this.loading) return 0;
    let remainingAmount = parseFloat(this.campaign.collectedAmount as any);
    const purchaseCard = this.form.get('purchaseCard');
    const giftAmount = this.form.get('giftAmount');
    if (purchaseCard) remainingAmount -= this.campaignCardPr;
    if (giftAmount.value) {
      remainingAmount -= parseFloat(giftAmount.value);
    }
    return remainingAmount;
  }

  get buttonDisable(): boolean {
    let disable = this.loading;

    Object.keys(this.form.controls).forEach((key) => {
      if (this.allField.includes(key) && this.form.get(key).invalid) {
        disable = true;
      }
    });

    if (this.form.get('cardType')?.value === 'prodigi') {
      if (this.prodigiForm?.invalid || !this.prdigiSelection?.selected) {
        disable = true;
      }
    }

    if (this.form.get('templateType')?.value === 'freeCard') {
      if (this.prodigiForm?.invalid || !this.prdigiSelection?.selected) {
        disable = false;
      }
    }

    if (this.sendOrSchedule) {
      if (
        !this.form.get('cardSendEmailDate')?.value ||
        !this.form.get('cardSendEmailTime')?.value
      ) {
        disable = true;
      }
    } else if (this.form.get('cardRecipientEmail')?.invalid) {
      disable = true;
    }
    0;

    if (
      this.form.get('cardRecipientEmail')?.invalid ||
      !this.form.get('cardRecipientEmail')?.value
    ) {
      disable = true;
    }
    if (
      this.form.get('payoutType').value === 'catalogue' ||
      this.form.get('payoutType').value === 'owngift'
    ) {
      if (
        this.form.get('confirmEmail')?.invalid ||
        !this.form.get('confirmEmail')?.value
      ) {
        disable = true;
      }
    }
    if (
      this.form.get('payoutType').value === 'catalogue' ||
      this.form.get('payoutType').value === 'owngift'
    ) {
      if (
        this.form.get('confirmEmail')?.value !==
        this.form.get('cardRecipientEmail')?.value
      ) {
        disable = true;
      }
    }

    if (!this.form.get('isAutoEmailToRecipient').value) {
      disable = false;
    }

    return disable;
  }

  get allField() {
    return [
      'charityId',
      'wantToDonate',
      'isGiftBought',
      'decidedGift',
      'purchaseCard',
      'cardAmount',
      // 'charityAmount',
      'userAmount',
      'giftAmount',
      'receipientEmail',
      'selectedTemplateUrl',
      // 'giftLink',
    ];
  }
  money: any;
  pendingAmount: any;
  valueChanges(e: any) {
    this.form.get('templateType')?.valueChanges.subscribe((d) => {
      this.campaignCardPr = undefined;
    });
    this.pendingAmount =
      this.campaign?.collectedAmount - (this.campaignCardPr || 4);
    this.money = e;
    if (this.money > this.pendingAmount) {
      this.form
        .get('giftAmount')
        .setValidators([
          Validators.pattern(AMOUNT_PATTERN),
          Validators.max(this.pendingAmount),
        ]);
      return this._appService.snackBarOpen(
        `You dont have sufficient funds in your collect-a-gift pot. The most you can redeem is ${this.pendingAmount.toFixed(
          2
        )}`
      );
    } else {
    }
  }

  openPayout() {
    this._matDialog.open(PayoutDialogComponent, {
      panelClass: 'charity-dialog',
      maxHeight: '600px',
      maxWidth: '350px',
      minWidth: '350px',
      height: 'auto',
      data: { this: this },
    });
  }

  campaignCardPrice() {
    const value = this.form.get('templateType').value;
    if (value === 'uploaded') {
      this.campaignCardPr = 4;
    } else {
      return;
    }
    return (this.campaignCardPr = 4);
  }

  sendNowOrSchedule(value: string) {
    if (value === 'now') {
      this.form.get('cardSendEmailDate').reset();
      this.form.get('cardSendEmailTime').reset();
      this.sendOrSchedule = false;
      return;
    }
    this.sendOrSchedule = true;
    if (this.form.get('cardSendEmailDate').value === '') this.disable = true;
  }
}
