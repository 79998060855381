import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Campaign } from 'src/app/models/campaign.model';
import { FireContribution } from 'src/app/models/contribution';
import { ProdigiProduct } from 'src/app/models/prodigiProduct.model';
import { AppService } from 'src/app/services/app.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { CardFirebaseService } from 'src/app/services/card-firebase.service';
import { ProdigiService } from 'src/app/services/prodigi.service';

@Component({
  selector: 'app-campaign-digital-card',
  templateUrl: './campaign-digital-card.component.html',
  styleUrls: ['./campaign-digital-card.component.scss'],
})
export class CampaignDigitalCardComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() campaign = new Campaign();
  @Input() cardType: string;
  campaignId: any;
  isLoading: boolean;
  prodigiProductDetail: ProdigiProduct;
  currentPage = 0;
  pages: PageM[] = [];
  wishesSubscription: Subscription;

  get cardDimensions() {
    const height = 392;
    const width = 277;
    return { height: `${height}px`, width: `${width}px` };
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appService: AppService,
    private _campaignService: CampaignService,
    private _prodigiService: ProdigiService,
    private _cardFirebase: CardFirebaseService,
    private renderer: Renderer2
  ) {
    this.campaignId = this._activatedRoute.snapshot.params['campaignId'];
  }

  ngOnDestroy(): void {
    if (this.wishesSubscription) {
      this.wishesSubscription.unsubscribe();
    }
  }

  subscribeWishes() {
    if (this.wishesSubscription) {
      this.wishesSubscription.unsubscribe();
    }
    this.wishesSubscription = this._cardFirebase
      .getContributions(this._campaignService.getCampaignId(this.campaign))
      .subscribe((d) => {
        d.forEach((i) => {
          i.page = i.page || 1;
          i.transform = `translate(${i.postion.x}px, ${i.postion.y}px)`;
        });

        let pagesMap = d.reduce(
          (pv, cv) => {
            if (!pv[cv.page]) {
              pv[cv.page] = {
                page: cv.page,
                wishes: [],
              };
            }
            pv[cv.page].wishes.push(cv);
            return pv;
          },
          {
            0: {
              page: 0,
              wishes: [],
              image: this.campaign.selectedTemplateUrl,
            },
          }
        );
        let pages = Object.values(pagesMap)
          .sort((a: any, b: any) => a.page - b.page)
          .map(({ wishes, image }) => ({ wishes, image }));
        this.pages = [];

        if (pages.length % 2 !== 0) {
          pages.push({ wishes: [], image: null });
        }

        for (let i = 0; i < pages.length; i += 2) {
          this.pages.push({
            flipped: false,
            page: i / 2 + 1,
            frontPage: pages[i],
            backPage: pages[i + 1],
          });
        }

        const lastPage = this.pages[this.pages.length - 1];
        if (lastPage?.backPage?.wishes?.length) {
          this.pages.push({
            flipped: false,
            page: lastPage?.page + 1,
            frontPage: { wishes: [] },
            backPage: { wishes: [] },
          });
        }

      });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['campaign'] && changes['campaign']?.currentValue?.id) {
      if (this.campaign?.id) this.subscribeWishes();
      await this.fetchProdigiProduct();
    }
  }

  async fetch(loading?: boolean) {
    if (loading) {
      this.isLoading = true;
    }
    this._campaignService
      .getByInviteLink(this.campaignId)
      .then(async (d) => {
        this.campaign = { ...d };
        this.fetchProdigiProduct();
        this.subscribeWishes();
      })
      .catch((err) => {
        this.isLoading = false;
        this._appService.snackBarOpen(err);
      });
  }

  ngOnInit() {
    if (this.campaignId) {
      this.fetch(true);
    }
    if (this.campaign?.id) {
      this.subscribeWishes();
    }
  }

  async fetchProdigiProduct() {
    if (
      this.campaign.cardType === 'digital' ||
      !this.campaign?.prodigiDetail?.productSku
    )
      return (this.isLoading = false);
    this.prodigiProductDetail = await this._prodigiService.productDetail(
      this.campaign?.prodigiDetail?.productSku
    );
    this.isLoading = false;
  }

  flipPage(index: number): void {
    this.pages[index].flipped = !this.pages[index].flipped;
    const isFlipped = this.pages[index].flipped;
    if (isFlipped) {
      if (index > 0) {
        this.pages[index - 1].backPage.opened = false;
      }
      this.pages[index].frontPage.opened = false;
      this.pages[index].backPage.opened = true;

      if (index < this.pages.length - 1) {
        this.pages[index + 1].frontPage.opened = true;
      }
    } else {
      if (index > 0) {
        this.pages[index - 1].backPage.opened = true;
      }
      this.pages[index].frontPage.opened = true;
      this.pages[index].backPage.opened = false;

      if (index < this.pages.length - 1) {
        this.pages[index + 1].frontPage.opened = false;
      }
    }
  }

  isAnyPageFlipped(): boolean {
    return this.pages.some((page) => page.flipped);
  }

  getItemStyles(item: any): { [key: string]: string } {
    return {
      color: item.color || 'black',
      backgroundColor: item.bgColor || 'transparent',

      fontFamily: item.fontFamily,
      transform: item.transform,
      fontSize: item.fontSize,

      fontWeight: item.fontWeight,

      left: item.left,

      textAlign: item.textAlign,

      textDecoration: item.textDecoration,

      top: item.top,
      width: item?.width || 'fit-content',
    };
  }
}

class PageM {
  flipped: boolean;
  frontPage: {
    wishes?: FireContribution[];
    image?: string;
    opened?: boolean;
  };
  backPage: {
    wishes?: FireContribution[];
    image?: string;
    opened?: boolean;
  };
  page?: number;
}
