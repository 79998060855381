import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll-to-element';

@Component({
  selector: 'app-Terms-Conditions',
  templateUrl: './Terms-Conditions.component.html',
  styleUrls: ['./Terms-Conditions.component.scss'],
})
export class TermsConditionsComponent implements AfterViewInit {
  constructor(private scrollService: ScrollService) {}

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  scrollToTarget(value: string) {
    this.scrollService.scrollToSection(value);
  }
}
