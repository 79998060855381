import { async } from '@angular/core/testing';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Campaign } from 'src/app/models/campaign.model';
import { AppService } from 'src/app/services/app.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { BuyGiftCardComponent } from '../../components/buy-gift-card/buy-gift-card.component';
import { CharityDialogComponent } from '../charity-dialog/charity-dialog.component';
import { SuccessMessageDialogComponent } from 'src/app/modules/shareModule/dialogs/success-message-dialog/success-message-dialog.component';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/services/campaign.service';
const AMOUNT_PATTERN = /^((^((?!(0))[0-9]*)$)|((0)?([1-9]*)\.[0-9]*)?)?$/i;

@Component({
  selector: 'app-donate-charity-dialog',
  templateUrl: './donate-charity-dialog-own-gift.component.html',
  styleUrls: ['./donate-charity-dialog-own-gift.component.scss'],
})
export class DonateCharityDialogOwnGiftComponent implements OnInit {
  cardPrice: any;
  giftAmount: number;
  isWantToDonate: boolean = false;
  isChecked: boolean = false;
  isLoading: boolean = false;
  @Input() campaign: Campaign = new Campaign();
  constructor(
    public dialogRef: MatDialogRef<DonateCharityDialogOwnGiftComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      campaign: Campaign;
      this: BuyGiftCardComponent;
      payoutType: any;
      cardVal: any;
      charityType: string;
      templateUrl: string;
      receipientEmail: string;
      sendType: boolean;
    },
    public _appService: AppService,
    public _campaignService: CampaignService,
    public _dialog: MatDialog,
    public configurationService: ConfigurationService,
    private _matDialog: MatDialog,
    private _router: Router
  ) {
    this.data;
    if (data.cardVal == 'uploaded') {
      data.this.campaignCardPr = 4;
    }
    this.cardPrice = data?.this?.campaignCardPr;
  }

  ngOnInit() {
    this.form
      .get('giftAmount')
      ?.setValidators([
        Validators.max(this.data?.this.campaign?.collectedAmount),
      ]);
    // if (this.data?.this?.cardVal == 'uploaded') {
    //   this.giftAmount =
    //     this.data?.this?.campaign?.collectedAmount -
    //     (this.data?.this?.money || 0) -
    //     4;
    //   this.form.controls.giftAmount.setValue(this.giftAmount);
    // }
    // if (this.data?.this?.cardVal == 'selected') {
    //   this.giftAmount =
    //     this.data?.this?.campaign?.collectedAmount -
    //     (this.data?.this.money || 0) -
    //     this.data?.this.campaignCardPr;
    //   this.form.controls.giftAmount.setValue(this.giftAmount);
    // }
    // if (this.data?.this?.cardVal == 'freeCard') {
    //   this.giftAmount = this.data?.this?.campaign?.collectedAmount;
    //   this.form.controls.giftAmount.setValue(this.giftAmount);
    // }
    this.reset();
  }

  public get campaignContent() {
    return this.configurationService?.campaignContent || {};
  }

  getNumberAfterDecimal(num: number) {
    if (Number.isInteger(num)) {
      return 0;
    }
    const decimalStr = '0' + '.' + num.toString().split('.')[1];
    return Number(decimalStr);
  }

  get form(): FormGroup {
    return this.data.this.form;
  }

  // get campaign(): Campaign {
  //   return this.data.this.campaign;
  // }

  get remainingAmount() {
    return this.data.this.remainingAmount;
  }

  get campaignCardPrice() {
    return this.data.this.campaignCardPr;
  }

  public wanttodonate;

  onWantToDonate(v: boolean) {
    this.wanttodonate = v;
    this.isWantToDonate = v;

    this.isChecked = !v;

    if (v == false) {
      this.form.get('giftAmount')?.setValue('');
      this.data.this.money = '';
    }
    this.data.this.onWantToDonate(v);
  }

  onCharityAmountTypeChange(type: 'autoFilled' | 'other') {
    this.data.this.onCharityAmountTypeChange(type);
  }

  reset() {
    this.form.controls.wantToDonate.setValue(true);
    this.onWantToDonate(true);
  }

  onClose(d?: any) {
    if (!d) {
      this.reset();
      this.form?.get('charityAmount')?.setValidators([]);
      this.form?.get('charityAmount')?.updateValueAndValidity();
    }
    this.data.this;
    this.dialogRef.close(d);
  }

  pendingAmount: any;

  valueChanges(e: any) {
    this.pendingAmount =
      this.data.campaign?.collectedAmount - (this.cardPrice || 0);
    this.data.this.money = e;

    if (this.data.this.money > this.pendingAmount) {
      // Set validators with max amount and pattern
      this.form
        .get('giftAmount')
        ?.setValidators([
          Validators.pattern(AMOUNT_PATTERN),
          Validators.max(this.pendingAmount),
        ]);

      // Force re-validation of the control
      this.form.get('giftAmount')?.setValue(this.pendingAmount);
      this.form.get('giftAmount')?.updateValueAndValidity();
      this.giftAmount = this.form.get('giftAmount')?.value;
      this.data.this.money = this.form.get('giftAmount')?.value;
      return this._appService.snackBarOpen(
        `This amount exceeds the maximum funds available left to redeem. Please enter an amount less than or equal to the total amount left to redeem.`
      );
    } else {
      this.giftAmount = this.form.get('giftAmount')?.value;
    }
  }

  successDialog() {
    // const title = `Thank you for redeeming your Collectagift Pot for ${this.data?.this?.campaign.receipientName}. An email will be sent to ${this.data?.this?.campaign.receipientName} at the email address provided containing the Redeem Link for them to choose their gift voucher together with their Greeting Card.  Thank you for using CollectaGift.  If you have any questions, please contact us anytime on hello@collectagift.com`;
    const title = `Thank you for redeeming your CollectaGift Pot for ${this.data?.this?.campaign.receipientName}.  An email with be sent to ${this.data?.this?.campaign.receipientName} at the email address provided containing the Redeem Link and their Greeting Card.  They can then use the Redeem Link to choose any voucher they wish from our Catalogue.  If you scheduled send, this email will be sent at the requested time, otherwise it will be sent straight away. Thank you for using CollectaGift. If you have any questions, please contact us anytime on hello@collectagift.com.`;
    this._matDialog
      .open(SuccessMessageDialogComponent, {
        data: {
          title: title,
        },
      })
      .afterClosed()
      .subscribe((d) => {
        this._router.navigate(['/pages/detail/campaigns']);
      });
  }

  async OpenCharityDialog() {
    let d = {
      campaignId: this.data.campaign.id,
      ...(this.data.cardVal !== 'freeCard' && {
        selectedTemplateUrl: this.data.templateUrl,
      }),
      templateType: this.data.cardVal,
      amount:
        this.form.get('giftAmount').value === ''
          ? this.data.this.campaign.collectedAmount -
            this.data.this.campaignCardPr
          : parseInt(this.form.get('giftAmount').value),

      currencyCode: 'GBP',
      isDonated: this.wanttodonate,
      charityAmount: this.remainingAmount,
      ...(this.data.sendType && {
        cardEmailTime: this.data.campaign.cardEmailTime,
      }),
      receipientEmail: this.data.receipientEmail,
    };

    const payload = this._campaignService.removeEmptyKeysValues(d);

    const data = await this._campaignService.redeemLink(payload);
    // const data = '';
    if (!data) {
      return (this.isLoading = false);
    }
    this.isLoading = false;

    this.dialogRef.close({ data });
    this.successDialog();

    return;
  }

  onChangeChecked(event: Event) {
    this.isChecked = (event.target as HTMLInputElement).checked;
  }
}
