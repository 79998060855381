import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CreditCardService } from './credit-card.service';

export class ValidationService {
  static cardNumber: string;

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      required: 'Field is required.',
      min: `Minimum value required ${
        validatorValue.min ? validatorValue.min : ''
      }`,
      max: `Maximum value should be ${
        validatorValue.max ? validatorValue.max : ''
      }`,
      maxlength: `Maximum word limit reached`,
      minlength: `Minimum ${
        validatorValue.requiredLength ? validatorValue.requiredLength : ''
      } characters are required`,
      invalidName: 'Enter a valid name',
      invalidEmailAddress: 'Invalid email address',
      // 'invalidPassword': 'Password should be at least 8 characters and must contain a number and a special character',
      invalidPassword:
        'Password must be a minimum of 8 characters and contain at least one special character and one number.',
      invalidNumber: 'Enter positive numeric values only',
      invalidPhoneNumber: 'Enter a valid phone number',
      invalidCardNumber: 'Please enter a valid credit/debit card number',
      invalidCVVNumber: 'Enter a valid CVV code',
      invalidCardExpiry: 'Invalid expiry date',
      invalidUrl: 'Enter a valid url starting with http://',
      invalidTaxId: 'Enter a valid TaxId',
      invalidFaxNumber: 'Enter a valid fax number',
      invalidZip: 'Please enter a valid zip code',
      invalidInput: 'Please enter a valid input',
      invalidGoal: 'Please enter valid goal. ',
      invalidYouTube: 'Please enter a valid youtube url',
      invalidAccountNumber: 'Please enter a valid account number',
      filedNotSame: `Passwords do not match`,
      emailsNotSame: `Email address does not match`,
    };
    return config[validatorName];
  }

  static spaceValidator(control: any) {
    // RFC 2822 compliant regex
    const value = `${control.value}`;
    if (value) {
      let str = value.replace(/\s+/g, '');
      if (str.length > 0) {
        return null;
      } else {
        return { invalidInput: true };
      }
    }
    return null;
  }

  static compareValidator(control1, control2) {
    return function matchPassword(c) {
      if (
        c.get(control1).value &&
        c.get(control2).value &&
        c.get(control1).value !== c.get(control2).value
      ) {
        c.get(control2).setErrors({ filedNotSame: true });
        return { filedNotSame: true };
      } else {
        return null;
      }
    };
  }

  static compareEmails(control1: string, control2: string): ValidatorFn {
    return (controlGroup: AbstractControl): ValidationErrors | null => {
      const email1Control = controlGroup.get(control1);
      const email2Control = controlGroup.get(control2);

      if (!email1Control || !email2Control) {
        return null;
      }

      const email1 = email1Control.value;
      const email2 = email2Control.value;

      if (email1 && email2 && email1 !== email2) {
        email2Control.setErrors({ emailsNotSame: true });
        return { emailsNotSame: true };
      }

      return null;
    };
  }

  static postalCodeValidator(countryCode: string): (control) => any {
    const redex = ValidationService.postalCode[countryCode];
    return (control) => {
      if (control.value.match(redex)) {
        return null;
      } else {
        return { invalidZip: true };
      }
    };
  }

  static numberFieldRequiredValidator(control: any) {
    if (control.value) {
      return null;
    } else {
      return { required: true };
    }
  }

  static fullNameValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      let str = control.value.replace(/\s+/g, '');

      if (
        control.value.match(/^([a-z])+([a-zA-Z0-9 .,-@&#!\'\\$\\s]*)$/i) &&
        str.length > 0
      ) {
        return null;
      } else {
        return { invalidName: true };
      }
    }
  }

  static nameValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      let str = control.value.replace(/\s+/g, '');

      if (
        control.value.match(/^([a-z])+([a-zA-Z0-9 .,-@&#!\'\\$\\s]*)$/i) &&
        str.length > 0
      ) {
        return null;
      } else {
        return { invalidName: true };
      }
    }
  }

  static zipValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (control.value.match(/^\d{5}([\-]?\d{4})?$/)) {
        return null;
      } else if (control.value.match(/^\d{6}$/)) {
        return null;
      } else {
        return { invalidZip: true };
      }
    }
  }

  static urlValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (
        control.value.match(
          /^http(s)?:\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        )
      ) {
        return null;
      } else {
        return { invalidUrl: true };
      }
    }
  }

  static websiteValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (
        control.value.match(
          /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/
        )
      ) {
        return null;
      } else {
        return { invalidUrl: true };
      }
    }
  }

  static facboolUrlValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (control.value.match(/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i)) {
        return null;
      } else {
        return { invalidUrl: true };
      }
    }
  }

  static twitterUrlValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (
        control.value.match(
          /^(https?:\/\/)?((w{3}\.)?)twitter\.com\/(#!\/)?[a-z0-9_]+$/i
        )
      ) {
        return null;
      } else {
        return { invalidUrl: true };
      }
    }
  }

  static emailValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (
        control.value.match(
          /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|glass|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
        )
      ) {
        return null;
      } else {
        return { invalidEmailAddress: true };
      }
    }
  }

  static emaiListValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (
        control.value.match(
          /^(\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,5}\s*?,?\s*?)+$/
        )
      ) {
        return null;
      } else {
        return { invalidEmailAddress: true };
      }
    }
  }

  static taxIdValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (control.value.match(/^([0-9]|[a-z])+([0-9a-z-]+)$/i)) {
        return null;
      } else {
        return { invalidTaxId: true };
      }
    }
  }

  static numberValidator(control: any) {
    if (control.value) {
      if (
        control.value.toString() &&
        control.value.toString().match(/^[0-9]*$/)
      ) {
        return null;
      } else {
        return { invalidNumber: true };
      }
    }
  }

  static goalValidator(control: any) {
    if (control.value) {
      if (
        control.value.toString() &&
        control.value.toString().match(/^[1-9][0-9]*$/)
      ) {
        return null;
      } else {
        return { invalidGoal: true };
      }
    }
  }

  static phoneNumberValidator(control: any) {
    if (control.value) {
      if (control.value.match(/^\d{10}$/)) {
        return null;
      } else if (
        control.value.match(
          /^(\+\d{1,3}[- ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        )
      ) {
        return null;
      } else if (
        control.value.match(
          /^(\+\d{1,3}[- ]?)?\(?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
        )
      ) {
        return null;
      } else {
        return { invalidPhoneNumber: true };
      }
    }
  }

  static phoneNumberWithoutDialCodeValidator(control: any) {
    if (control.value) {
      if (control.value.match(/^\d{10,10}$/)) {
        return null;
      } else {
        return { invalidPhoneNumber: true };
      }
    }
  }

  static faxNumberValidator(control: any) {
    if (control.value) {
      if (control.value.match(/^\d{10}$/)) {
        return null;
      } else if (
        control.value.match(
          /^(\+\d{1,3}[- ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        )
      ) {
        return null;
      } else if (
        control.value.match(
          /^(\+\d{1,3}[- ]?)?\(?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
        )
      ) {
        return null;
      } else {
        return { invalidFaxNumber: true };
      }
    }
  }

  static setCardNumber(cardNumber: string) {
    this.cardNumber = cardNumber;
  }

  static getCardNumber(): string {
    return this.cardNumber;
  }

  static passwordValidator(control: any): any {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?=.*[!@#$%^&*])       - Assert a string has at least one spacial charactor

    if (control.value) {
      if (
        control.value.match(
          /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,100}$/
        )
      ) {
        return null;
      } else {
        return { invalidPassword: true };
      }
    }
  }

  static creditCardNumberValidator(control: any) {
    if (control.value) {
      let creditService = new CreditCardService();
      if (creditService.validateCardNumber(control.value)) {
        ValidationService.setCardNumber(control.value);
        return null;
      } else {
        ValidationService.setCardNumber(null);
        return { invalidCardNumber: true };
      }
    }
  }

  static bankAccountNumberValidator(control: any) {
    if (control.value) {
      let creditService = new CreditCardService();
      if (creditService.validateBankAccountNumber(control.value)) {
        return null;
      } else {
        return { invalidAccountNumber: true };
      }
    }
  }

  static creditCardCVVNumberValidator(control: any) {
    if (control.value) {
      let creditService = new CreditCardService();
      if (creditService.validateCardNumber(ValidationService.getCardNumber())) {
        if (
          creditService.validateCardCVC(
            control.value,
            creditService.parseCardType(ValidationService.getCardNumber())
          )
        ) {
          return null;
        } else {
          return { invalidCVVNumber: true };
        }
      }
    }
  }

  static creditCardExpiryDateValidator(control: any) {
    let creditService = new CreditCardService();
    if (control.value) {
      let partsOfStr = control.value.split('/');
      if (creditService.validateCardExpiry(partsOfStr[0], partsOfStr[1])) {
        return null;
      } else {
        return { invalidCardExpiry: true };
      }
    }
  }

  static validateYouTubeUrl(control: any) {
    var url = control.value;
    if (url != undefined || url != '') {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        return null;
      } else {
        return { invalidYouTube: true };
      }
    }
  }

  /*
   ZIP CODES
   "US"=>"^\d{5}([\-]?\d{4})?$",
   "UK"=>"^(GIR|[A-Z]\d[A-Z\d]??|[A-Z]{2}\d[A-Z\d]??)[ ]??(\d[A-Z]{2})$",
   "DE"=>"\b((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))\b",
   "CA"=>"^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])\ {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$",
   "FR"=>"^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$",
   "IT"=>"^(V-|I-)?[0-9]{5}$",
   "AU"=>"^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$",
   "NL"=>"^[1-9][0-9]{3}\s?([a-zA-Z]{2})?$",
   "ES"=>"^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$",
   "DK"=>"^([D|d][K|k]( |-))?[1-9]{1}[0-9]{3}$",
   "SE"=>"^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$",
   "BE"=>"^[1-9]{1}[0-9]{3}$",
   "IN"=>"^\d{6}$"
   */
  static postalCode = {
    GB: /GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}/i,
    JE: /JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/i,
    GG: /GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/i,
    IM: /IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/i,
    US: /\d{5}([ \-]\d{4})?/i,
    CA: /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/i,
    DE: /\d{5}/i,
    JP: /\d{3}-\d{4}/i,
    FR: /\d{2}[ ]?\d{3}/i,
    AU: /\d{4}/i,
    IT: /\d{5}/i,
    CH: /\d{4}/i,
    AT: /\d{4}/i,
    ES: /\d{5}/i,
    NL: /\d{4}[ ]?[A-Z]{2}/i,
    BE: /\d{4}/i,
    DK: /\d{4}/i,
    SE: /\d{3}[ ]?\d{2}/i,
    NO: /\d{4}/i,
    BR: /\d{5}[\-]?\d{3}/i,
    PT: /\d{4}([\-]\d{3})?/i,
    FI: /\d{5}/i,
    AX: /22\d{3}/i,
    KR: /\d{3}[\-]\d{3}/i,
    CN: /\d{6}/i,
    TW: /\d{3}(\d{2})?/i,
    SG: /\d{6}/i,
    DZ: /\d{5}/i,
    AD: /AD\d{3}/i,
    AR: /([A-HJ-NP-Z])?\d{4}([A-Z]{3})?/i,
    AM: /(37)?\d{4}/i,
    AZ: /\d{4}/i,
    BH: /((1[0-2]|[2-9])\d{2})?/i,
    BD: /\d{4}/i,
    BB: /(BB\d{5})?/i,
    BY: /\d{6}/i,
    BM: /[A-Z]{2}[ ]?[A-Z0-9]{2}/i,
    BA: /\d{5}/i,
    IO: /BBND 1ZZ/i,
    BN: /[A-Z]{2}[ ]?\d{4}/i,
    BG: /\d{4}/i,
    KH: /\d{5}/i,
    CV: /\d{4}/i,
    CL: /\d{7}/i,
    CR: /\d{4,5}|\d{3}-\d{4}/i,
    HR: /\d{5}/i,
    CY: /\d{4}/i,
    CZ: /\d{3}[ ]?\d{2}/i,
    DO: /\d{5}/i,
    EC: /([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?/i,
    EG: /\d{5}/i,
    EE: /\d{5}/i,
    FO: /\d{3}/i,
    GE: /\d{4}/i,
    GR: /\d{3}[ ]?\d{2}/i,
    GL: /39\d{2}/i,
    GT: /\d{5}/i,
    HT: /\d{4}/i,
    HN: /(?:\d{5})?/i,
    HU: /\d{4}/i,
    IS: /\d{3}/i,
    IN: /\d{6}/i,
    ID: /\d{5}/i,
    IL: /\d{5}/i,
    JO: /\d{5}/i,
    KZ: /\d{6}/i,
    KE: /\d{5}/i,
    KW: /\d{5}/i,
    LA: /\d{5}/i,
    LV: /\d{4}/i,
    LB: /(\d{4}([ ]?\d{4})?)?/i,
    LI: /(948[5-9])|(949[0-7])/i,
    LT: /\d{5}/i,
    LU: /\d{4}/i,
    MK: /\d{4}/i,
    MY: /\d{5}/i,
    MV: /\d{5}/i,
    MT: /[A-Z]{3}[ ]?\d{2,4}/i,
    MU: /(\d{3}[A-Z]{2}\d{3})?/i,
    MX: /\d{5}/i,
    MD: /\d{4}/i,
    MC: /980\d{2}/i,
    MA: /\d{5}/i,
    NP: /\d{5}/i,
    NZ: /\d{4}/i,
    NI: /((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?/i,
    NG: /(\d{6})?/i,
    OM: /(PC )?\d{3}/i,
    PK: /\d{5}/i,
    PY: /\d{4}/i,
    PH: /\d{4}/i,
    PL: /\d{2}-\d{3}/i,
    PR: /00[679]\d{2}([ \-]\d{4})?/i,
    RO: /\d{6}/i,
    RU: /\d{6}/i,
    SM: /4789\d/i,
    SA: /\d{5}/i,
    SN: /\d{5}/i,
    SK: /\d{3}[ ]?\d{2}/i,
    SI: /\d{4}/i,
    ZA: /\d{4}/i,
    LK: /\d{5}/i,
    TJ: /\d{6}/i,
    TH: /\d{5}/i,
    TN: /\d{4}/i,
    TR: /\d{5}/i,
    TM: /\d{6}/i,
    UA: /\d{5}/i,
    UY: /\d{5}/i,
    UZ: /\d{6}/i,
    VA: /00120/i,
    VE: /\d{4}/i,
    ZM: /\d{5}/i,
    AS: /96799/i,
    CC: /6799/i,
    CK: /\d{4}/i,
    RS: /\d{6}/i,
    ME: /8\d{4}/i,
    CS: /\d{5}/i,
    YU: /\d{5}/i,
    CX: /6798/i,
    ET: /\d{4}/i,
    FK: /FIQQ 1ZZ/i,
    NF: /2899/i,
    FM: /(9694[1-4])([ \-]\d{4})?/i,
    GF: /9[78]3\d{2}/i,
    GN: /\d{3}/i,
    GP: /9[78][01]\d{2}/i,
    GS: /SIQQ 1ZZ/i,
    GU: /969[123]\d([ \-]\d{4})?/i,
    GW: /\d{4}/i,
    HM: /\d{4}/i,
    IQ: /\d{5}/i,
    KG: /\d{6}/i,
    LR: /\d{4}/i,
    LS: /\d{3}/i,
    MG: /\d{3}/i,
    MH: /969[67]\d([ \-]\d{4})?/i,
    MN: /\d{6}/i,
    MP: /9695[012]([ \-]\d{4})?/i,
    MQ: /9[78]2\d{2}/i,
    NC: /988\d{2}/i,
    NE: /\d{4}/i,
    VI: /008(([0-4]\d)|(5[01]))([ \-]\d{4})?/i,
    PF: /987\d{2}/i,
    PG: /\d{3}/i,
    PM: /9[78]5\d{2}/i,
    PN: /PCRN 1ZZ/i,
    PW: /96940/i,
    RE: /9[78]4\d{2}/i,
    SH: /(ASCN|STHL) 1ZZ/i,
    SJ: /\d{4}/i,
    SO: /\d{5}/i,
    SZ: /[HLMS]\d{3}/i,
    TC: /TKCA 1ZZ/i,
    WF: /986\d{2}/i,
    XK: /\d{5}/i,
    YT: /976\d{2}/i,
  };
}
