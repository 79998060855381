<div class="container-fluid">
  <h3 class="headtitle">BUY GIFT AND CARD</h3>
  <form [formGroup]="form" autocapitalize="off">
    <mat-card class="mat-card p-4" *ngIf="campaign.status === 'active'">
      <div class="text-center">
        <img
          class="py-3"
          height="200px"
          src="assets/images/Congratulations.jpg"
          alt=""
        />
        <h4 class="text-mute">
          You're almost there! You've collected
          {{
            campaign?.collectedAmount
              | currency : campaign?.campaignCurrency || "GBP"
          }}
          so far for
          {{ this.form.get("receipientName")?.value || "N/a" }}'s gift.
          {{ campaignContent?.buyGiftCard?.description1 || "--" }}
          In case you missed it,
          <a href="/pages/contribution/{{ campaignId }}" target="_blank"
            >here is the link</a
          >
          again to the CollectaGift Pot. Please share this email and ask your
          friends, family, colleagues to contribute. Don't worry, you'll also
          receive this link to your registered email address, so you can access
          it at any time.
          <!-- Come back here once the CollectaGift Pot has closed to redeem your gift amount. -->
        </h4>
      </div>
    </mat-card>

    <mat-card
      class="mat-card p-4 faq"
      *ngIf="campaign.status === 'closed' || campaign.status == 'dateAchieved'"
    >
      <!-- isExprie -->
      <div class="text-center">
        <img
          class="py-3"
          height="200px"
          src="assets/images/Congratulations.jpg"
          alt=""
        />
        <h4 class="text-mute">
          <!-- Congratulations, you have collected -->
          {{ campaignContent?.buyGiftCardPaid?.description1 || "" }}
          {{
            campaign?.collectedAmount
              | currency : campaign?.campaignCurrency || "GBP"
          }}
          for
          {{ this.form.get("receipientName")?.value }}
          <!-- Way to go, you’ve collected {{(campaign?.collectedAmount | currency:(campaign?.campaignCurrency|| "GBP"))}}
          for
          {{this.form.get('receipientName')?.value}}. -->
        </h4>
      </div>

      <div class="form-group">
        <label class="info">
          <!-- 1. {{ campaignContent?.buyGiftCardPaid?.question1 || "" }} -->
          1. Which card option would you like?
          <mat-icon
            tooltip="The message wall is free, otherwise you can
          choose to purchase an e-card.  If you have enjoyed using this
          service, we would ask you to consider purchasing an e-card option
          as we do not generate any other income as part of this service."
          >
            info
          </mat-icon>
        </label>
      </div>

      <div class="my-3" *ngIf="form.get('purchaseCard')?.value">
        <div class="form-group d-flex">
          <mat-radio-group
            class="orange"
            (ngModelChange)="onTemplateTypeChange($event)"
            formControlName="templateType"
          >
            <mat-radio-button value="uploaded" class="faq-div">
              <label class="info h-italic mb-0">
                {{ campaignContent?.buyGiftCardPaid?.question1IfYesA || "" }}
                (£4)
              </label>
            </mat-radio-button>
            <mat-radio-button value="selected" class="ml-4 mr-2 faq-div">
              <label class="info h-italic mb-0 faq-div1">
                {{ campaignContent?.buyGiftCardPaid?.question1IfYesB || "" }}
                (From £3)
              </label>
            </mat-radio-button>
            <mat-radio-button value="freeCard" class="ml-4 mr-0 faq-div">
              <label class="info h-italic mb-0 faq-div1">
                <!-- Download message wall (Free) -->
                Free Greeting Card (£0)
                <mat-icon
                  class="question"
                  tooltip="The front cover design of the Free Greeting Card will be the CollectaGift logo.  All messages will be visible inside."
                  >info</mat-icon
                >
              </label>
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="row">
          <div
            class="col-12 col-lg-4"
            *ngIf="form?.get('templateType').value === 'selected'"
          >
            <div>
              <label class="h-italic"
                >Please select a card cover for the card</label
              >
            </div>
            <div class="mb-4 p-0">
              <select
                class="form-control"
                [ngModelOptions]="{ standalone: true }"
                (change)="fetchTemplate()"
                [(ngModel)]="templates.filters.properties['categoryId'].value"
              >
                <option
                  *ngFor="let item of categories?.items"
                  [value]="item?.id"
                >
                  {{ item?.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div
            class="d-flex digitalCardimg"
            *ngIf="form?.get('templateType').value === 'uploaded'"
          >
            <div class="temp-outer pr-4 mb-4">
              <div
                (click)="
                  this.campaign?.isAmountProcessed ? null : createCollage()
                "
              >
                <img
                  height="155px"
                  src="assets/images/makeCoverBtn.svg"
                  alt=""
                />
              </div>
            </div>
            <div class="temp-outer pr-4 mb-4">
              <div
                class="template border-0"
                style="width: auto; cursor: pointer"
                *ngIf="form.get('selectedTemplateUrl')?.value"
              >
                <img
                  [src]="form.get('selectedTemplateUrl')?.value"
                  height="155px"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            class="d-flex digitalCardimg"
            *ngIf="form?.get('templateType').value === 'selected'"
          >
            <div class="temp-outer p-3 m-auto" *ngIf="templates?.isLoading">
              <mat-spinner [strokeWidth]="1" [diameter]="50"></mat-spinner>
            </div>
            <ng-container *ngFor="let item of templates.items">
              <div
                class="temp-outer pr-4 mb-4"
                (click)="
                  this.campaign?.isAmountProcessed ||
                  item?.price > campaign?.collectedAmount
                    ? null
                    : onSelectTemplate(false, item.url, item?.price)
                "
              >
                <div
                  [ngClass]="
                    item?.price > campaign?.collectedAmount ? 'not-allowed' : ''
                  "
                  class="template hover"
                  [class.active]="
                    item?.url === form.get('selectedTemplateUrl')?.value
                  "
                  [style.background-image]="'url(' + item?.url + ')'"
                >
                  <div class="price">
                    {{
                      item?.price
                        | currency : campaign?.campaignCurrency || "GBP"
                    }}
                  </div>
                  <div class="triangle"></div>
                  <mat-icon>check</mat-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="mb-3" *ngIf="isAlignWishes">
          <a
            *ngIf="form?.get('templateType').value != 'freeCard'"
            class="skyblue a"
            (click)="createCard()"
            >Preview card here (with option to rearrange the wishes)
          </a>
        </div>
        <div>
          <a
            *ngIf="form?.get('templateType').value === 'freeCard'"
            class="skyblue a"
            (click)="createCard('freeCard')"
            >Preview card here (with option to rearrange the wishes)
          </a>
        </div>

        <div class="d-none">
          <div class="form-group mb-4 faq-div">
            <label class="mb-3 info"
              >2. Would you like the digital card to be auto-emailed to the
              recipient, or would you like to receive the digital card link to
              manually send it to the recipient?
              <mat-icon
                class="question"
                tooltip="Regardless of which option you choose, the digital card can always be downloaded at any time from your dashboard and as the organiser, a copy of the digital card will also be sent to your registered email address."
                >info</mat-icon
              >
            </label>
            <br />
            <mat-radio-group
              class="orange text-mute faq-div"
              formControlName="automaticCardEmail"
              (change)="onEmailTypeChange($event)"
            >
              <mat-radio-button [value]="true"
                >Auto-Email to Recipient</mat-radio-button
              >
              <mat-radio-button class="ml-4 faq-div" [value]="false">
                <label for="" class="info maunally">
                  Manually email to Recipient
                  <mat-icon
                    class="question"
                    tooltip="If you have chosen the Free Message Wall option please click on “View all wishes” which gives you the option to download the wishes too."
                    >info</mat-icon
                  >
                </label>
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div *ngIf="form.get('automaticCardEmail')?.value">
            <div class="form-group faq-div">
              <label class="info h-italic">
                Please enter the email address of the recipient of where the
                digital card should be sent?
                <mat-icon
                  tooltip="Card will be sent as an image which can be shared with anyone"
                >
                  info</mat-icon
                >
              </label>
              <input
                type="email"
                class="form-control w-50"
                placeholder="Recipients Email"
                placeholder="Email"
                formControlName="cardRecipientEmail"
              />
            </div>

            <div class="form-group mb-4">
              <label class="h-italic"
                >Please select a time and date for when the digital card should
                be delivered</label
              >
              <div class="d-flex">
                <div class="datepick mr-3">
                  <input
                    matInput
                    class="form-control"
                    [min]="currentDate"
                    formControlName="cardSendEmailDate"
                    [matDatepicker]="datePicker"
                    (click)="datePicker.open()"
                    (dateChange)="onDateChange($event)"
                  />

                  <img
                    class="dateicon"
                    (click)="datePicker.open()"
                    src="assets/icons/ic_calender.svg"
                  />
                  <mat-datepicker #datePicker></mat-datepicker>
                </div>
                <div class="timepick">
                  <input
                    class="form-control"
                    formControlName="cardSendEmailTime"
                    [ngxTimepicker]="toggleIcon"
                    [min]="currentTime"
                  />
                  <ngx-material-timepicker-toggle [for]="toggleIcon">
                    <svg
                      class="timeicon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      width="20px"
                      height="20px"
                      ngxMaterialTimepickerToggleIcon
                    >
                      <path
                        d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,16H7.995 C7.445,16,7,15.555,7,15.005v-0.011C7,14.445,7.445,14,7.995,14H14V5.995C14,5.445,14.445,5,14.995,5h0.011 C15.555,5,16,5.445,16,5.995V16z"
                      />
                    </svg>
                  </ngx-material-timepicker-toggle>
                  <ngx-material-timepicker
                    #toggleIcon
                  ></ngx-material-timepicker>
                </div>
                <label class="info d-flex mb-0">
                  <mat-icon
                    tooltip="Please note, that the earliest you can schedule a card to be sent is 30 minutes from now.  This will allow you to complete the payout."
                  >
                    info
                  </mat-icon>
                </label>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              form.get('automaticCardEmail')?.value === 'manually' &&
              !form.get('finalTemplate')?.value &&
              form.get('templateType')?.value != 'freeCard' &&
              campaignId
            "
          >
            <a class="skyblue a" (click)="copyCardLink()">Copy card link</a>
          </div>
        </div>
      </div>

      <div class="form-group mb-4">
        <label class="mb-3 info">
          <!-- How would you like to redeem the funds? -->
          2. {{ campaignContent?.buyGiftCardPaid?.question2 || "" }}
        </label>
        <br />
        <mat-radio-group
          class="orange flex-how-to text-mute d-flex"
          formControlName="payoutType"
          #payoutType="matRadioGroup"
          value="catalogue"
        >
          <mat-radio-button
            class="faq-div"
            [value]="'catalogue'"
            (click)="form.get('giftAmount')?.setValue('')"
          >
            <label class="info h-italic mb-0">
              Select Voucher from gift catalogue
              <mat-icon
                tooltip="Choose this option if you would like to gift a voucher for a specific brand from our catalogue or if you would like to buy a specific gift for the Recipient from one of the brands in our catalogue.  If you would like to purchase a gift from a brand in our catalogue, purchase the voucher and select 'Send the voucher just to myself' and then you can use the voucher to purchase a gift for the Recipient"
              >
                info
              </mat-icon>
            </label>
          </mat-radio-button>

          <mat-radio-button [value]="'owngift'" class="ml-4 option-2">
            <label class="info h-italic mb-0">
              Allow Recipient to choose their own Voucher
              <mat-icon
                tooltip="Choose this option if you're not sure which voucher to select for the Recipient.  The Recipient will receive a link whereby they can choose their own voucher from our Gift Catalogue"
              >
                info
              </mat-icon>
            </label>
          </mat-radio-button>

          <a
            class="skyblue ml-3 link"
            (click)="
              $event.stopPropagation();
              $event.preventDefault();
              saveAndContinue(true)
            "
          >
            Browse Gift Catalogue here
          </a>
        </mat-radio-group>
      </div>

      <div *ngIf="this.form.get('payoutType').value === 'catalogue'" class="">
        <div class="form-group mb-4 faq-div">
          <label class="mb-3 info"
            >3. Who would you like to send the gift voucher to?
          </label>
          <br />

          <mat-radio-group
            class="orange text-mute faq-div"
            formControlName="sendToRecipientEmail"
            (change)="onEmailTypeChange($event)"
          >
            <mat-radio-button [value]="true">
              <span class="info">
                Send the Voucher Directly to the Recipient
                <mat-icon
                  class="question"
                  tooltip="Choose this option if you want to gift a voucher directly to the Recipient for their favorite shop from our Gift Catalogue, which they can then spend as they wish. You can either send it straight away or schedule a day and time for it to be sent automatically."
                  >info</mat-icon
                >
              </span>
            </mat-radio-button>
            <mat-radio-button class="ml-4 faq-div" [value]="false">
              <label for="" class="info maunally">
                Send the Voucher just to myself
                <mat-icon
                  class="question"
                  tooltip="Choose this option if you would like to use the voucher to buy a gift for the Recipient from a specific shop in our catalogue. The Gift Voucher will just be sent to you to purchase the gift however you can setup the Greeting Card to be automatically sent to the Recipient if you wish."
                  >info</mat-icon
                >
              </label>
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="form.get('sendToRecipientEmail')?.value">
          <form [formGroup]="form">
            <div class="form-group faq-div">
              <label class="info h-italic">
                Please enter the Recipient's email address where you could like
                the Gift Voucher and Greeting Card to be sent:
              </label>

              <label class="info h-italic"> Email </label>
              <input
                type="email"
                class="form-control w-50 mb-3"
                placeholder="Recipients Email"
                formControlName="cardRecipientEmail"
              />
              <control-messages
                [control]="form.get('cardRecipientEmail')"
              ></control-messages>

              <label class="info h-italic"> Confirm Email </label>
              <input
                type="email"
                class="form-control w-50"
                placeholder="Confirm Email"
                formControlName="confirmEmail"
              />
              <control-messages
                [control]="form.get('confirmEmail')"
              ></control-messages>
            </div>
          </form>

          <p>
            When would you like the Gift Voucher & Greeting Card to be sent?
          </p>
          <div class="d-flex gap-20">
            <button
              [ngStyle]="
                !sendOrSchedule
                  ? { 'box-shadow': '0px 4px 6px rgb(93 86 86)' }
                  : {}
              "
              (click)="sendNowOrSchedule('now')"
              class="theme-btn orange"
              ButtonUi
              color="c1"
            >
              Send Now
            </button>
            <button
              [ngStyle]="
                sendOrSchedule
                  ? { 'box-shadow': '0px 4px 6px rgb(93 86 86)' }
                  : {}
              "
              (click)="sendNowOrSchedule('schedule')"
              class="theme-btn orange"
              ButtonUi
              color="c1"
            >
              Schedule Send
            </button>
          </div>
          <br />
          <div *ngIf="sendOrSchedule" class="form-group mb-4">
            <label class="h-italic"
              >Please select a time and date for when the digital card should be
              delivered</label
            >
            <div class="d-flex">
              <div class="datepick mr-3">
                <input
                  matInput
                  class="form-control"
                  [min]="currentDate"
                  formControlName="cardSendEmailDate"
                  [matDatepicker]="datePicker"
                  (click)="datePicker.open()"
                  (dateChange)="onDateChange($event)"
                />
                <!-- <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle> -->
                <!-- <mat-datepicker #picker1></mat-datepicker> -->
                <img
                  class="dateicon"
                  (click)="datePicker.open()"
                  src="assets/icons/ic_calender.svg"
                />
                <mat-datepicker #datePicker></mat-datepicker>
              </div>
              <div class="timepick">
                <input
                  class="form-control"
                  formControlName="cardSendEmailTime"
                  [ngxTimepicker]="toggleIcon"
                  [min]="currentTime"
                />
                <ngx-material-timepicker-toggle [for]="toggleIcon">
                  <svg
                    class="timeicon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    width="20px"
                    height="20px"
                    ngxMaterialTimepickerToggleIcon
                  >
                    <path
                      d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,16H7.995 C7.445,16,7,15.555,7,15.005v-0.011C7,14.445,7.445,14,7.995,14H14V5.995C14,5.445,14.445,5,14.995,5h0.011 C15.555,5,16,5.445,16,5.995V16z"
                    />
                  </svg>
                </ngx-material-timepicker-toggle>
                <ngx-material-timepicker #toggleIcon></ngx-material-timepicker>
              </div>
              <label class="info d-flex mb-0">
                <mat-icon
                  tooltip="Please note, that the earliest you can schedule a card to be sent is 30 minutes from now.  This will allow you to complete the payout."
                >
                  info
                </mat-icon>
              </label>
            </div>
          </div>
        </div>

        <div *ngIf="!form.get('sendToRecipientEmail')?.value">
          <p>
            <b>How would you like the Greeting Card to be sent? </b>
          </p>

          <mat-radio-group
            class="orange text-mute faq-div"
            formControlName="isAutoEmailToRecipient"
            (change)="onEmailType($event)"
          >
            <mat-radio-button [value]="true">
              <label for="" class="info maunally">
                Auto-Email to Recipient
                <mat-icon
                  class="question"
                  tooltip="This will allow you to schedule the Greeting Card to be automatically sent to the Recipient at a date and time that you choose."
                  >info</mat-icon
                >
              </label>
            </mat-radio-button>
            <mat-radio-button class="ml-4 faq-div" [value]="false">
              <label for="" class="info maunally">
                Manually email to Recipient
                <mat-icon
                  class="question"
                  tooltip="You will receive the Greeting Card via email which you can manually forward to the Recipient whenever you want."
                  >info</mat-icon
                >
              </label>
            </mat-radio-button>
          </mat-radio-group>

          <div *ngIf="form.get('isAutoEmailToRecipient')?.value">
            <div class="form-group faq-div">
              <label class="info h-italic">
                Auto-Email to Recipient Please enter the Recipient’s email
                address where you would like the Greeting Card to be sent
                <mat-icon
                  tooltip="Card will be sent as an image which can be shared with anyone"
                >
                  info</mat-icon
                >
              </label>
              <br>
              <label class="info h-italic">Email</label>
              <input
                type="email"
                class="form-control w-50"
                placeholder="Recipients Email"
                placeholder="Email"
                formControlName="cardRecipientEmail"
              />
              <label class="info h-italic"> Confirm Email </label>
              <input
                type="email"
                class="form-control w-50"
                placeholder="Confirm Email"
                formControlName="confirmEmail"
              />
              <control-messages
                [control]="form.get('confirmEmail')"
              ></control-messages>
            </div>

            <div class="form-group mb-4">
              <label class="h-italic"
                >Please select a time and date for when the digital card should
                be delivered</label
              >
              <div class="d-flex gap-20">
                <button
                  [ngStyle]="
                    !sendOrSchedule
                      ? { 'box-shadow': '0px 4px 6px rgb(93 86 86)' }
                      : {}
                  "
                  (click)="sendNowOrSchedule('now')"
                  class="theme-btn orange"
                  ButtonUi
                  color="c1"
                >
                  Send Now
                </button>
                <button
                  [ngStyle]="
                    sendOrSchedule
                      ? { 'box-shadow': '0px 4px 6px rgb(93 86 86)' }
                      : {}
                  "
                  (click)="sendNowOrSchedule('schedule')"
                  class="theme-btn orange"
                  ButtonUi
                  color="c1"
                >
                  Schedule Send
                </button>
              </div>
              <br />
              <div *ngIf="sendOrSchedule" class="form-group mb-4">
                <div class="d-flex">
                  <div class="datepick mr-3">
                    <input
                      matInput
                      class="form-control"
                      [min]="currentDate"
                      formControlName="cardSendEmailDate"
                      [matDatepicker]="datePicker"
                      (click)="datePicker.open()"
                      (dateChange)="onDateChange($event)"
                    />
                    <!-- <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle> -->
                    <!-- <mat-datepicker #picker1></mat-datepicker> -->
                    <img
                      class="dateicon"
                      (click)="datePicker.open()"
                      src="assets/icons/ic_calender.svg"
                    />
                    <mat-datepicker #datePicker></mat-datepicker>
                  </div>
                  <div class="timepick">
                    <input
                      class="form-control"
                      formControlName="cardSendEmailTime"
                      [ngxTimepicker]="toggleIcon"
                      [min]="currentTime"
                    />
                    <ngx-material-timepicker-toggle [for]="toggleIcon">
                      <svg
                        class="timeicon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                        width="20px"
                        height="20px"
                        ngxMaterialTimepickerToggleIcon
                      >
                        <path
                          d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,16H7.995 C7.445,16,7,15.555,7,15.005v-0.011C7,14.445,7.445,14,7.995,14H14V5.995C14,5.445,14.445,5,14.995,5h0.011 C15.555,5,16,5.445,16,5.995V16z"
                        />
                      </svg>
                    </ngx-material-timepicker-toggle>
                    <ngx-material-timepicker
                      #toggleIcon
                    ></ngx-material-timepicker>
                  </div>
                  <label class="info d-flex mb-0">
                    <mat-icon
                      tooltip="Please note, that the earliest you can schedule a card to be sent is 30 minutes from now.  This will allow you to complete the payout."
                    >
                      info
                    </mat-icon>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            form.get('automaticCardEmail')?.value === 'manually' &&
            !form.get('finalTemplate')?.value &&
            form.get('templateType')?.value != 'freeCard' &&
            campaignId
          "
        >
          <a class="skyblue a" (click)="copyCardLink()">Copy card link</a>
        </div>
      </div>

      <div *ngIf="this.form.get('payoutType').value === 'owngift'" class="">
        <div>
          <form [formGroup]="form">
            <div class="form-group faq-div">
              <label class="info h-italic">
                Please enter the Recipient's email address where you would like
                the Redeem Link and Greeting Card to be sent
                <mat-icon
                  tooltip="Card will be sent as an image which can be shared with anyone"
                >
                  info</mat-icon
                >
              </label>
              <label class="info h-italic" for="">Email</label>
              <input
                type="email"
                class="form-control w-50 mb-3"
                placeholder="Recipients Email"
                formControlName="cardRecipientEmail"
              />
              <control-messages
                [control]="form.get('cardRecipientEmail')"
              ></control-messages>
              <label class="info h-italic"> Confirm Email </label>
              <input
                type="email"
                class="form-control w-50"
                placeholder="Confirm Email"
                formControlName="confirmEmail"
              />
              <control-messages
                [control]="form.get('confirmEmail')"
              ></control-messages>
            </div>
          </form>
          <p>
            When would you like the CollectaGift Redeem Link & Greeting Card to
            be sent?
          </p>
          <div class="d-flex gap-20">
            <button
              [ngStyle]="
                !sendOrSchedule
                  ? { 'box-shadow': '0px 4px 6px rgb(93 86 86)' }
                  : {}
              "
              (click)="sendNowOrSchedule('now')"
              class="theme-btn orange"
              ButtonUi
              color="c1"
            >
              Send Now
            </button>
            <button
              [ngStyle]="
                sendOrSchedule
                  ? { 'box-shadow': '0px 4px 6px rgb(93 86 86)' }
                  : {}
              "
              (click)="sendNowOrSchedule('schedule')"
              class="theme-btn orange"
              ButtonUi
              color="c1"
            >
              Schedule Send
            </button>
          </div>
          <br />
          <div *ngIf="sendOrSchedule" class="form-group mb-4">
            <label class="h-italic"
              >Please select a time and date for when the digital card should be
              delivered</label
            >
            <div class="d-flex">
              <div class="datepick mr-3">
                <input
                  matInput
                  class="form-control"
                  [min]="currentDate"
                  formControlName="cardSendEmailDate"
                  [matDatepicker]="datePicker"
                  (click)="datePicker.open()"
                  (dateChange)="onDateChange($event)"
                />
                <!-- <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle> -->
                <!-- <mat-datepicker #picker1></mat-datepicker> -->
                <img
                  class="dateicon"
                  (click)="datePicker.open()"
                  src="assets/icons/ic_calender.svg"
                />
                <mat-datepicker #datePicker></mat-datepicker>
              </div>
              <div class="timepick">
                <input
                  class="form-control"
                  formControlName="cardSendEmailTime"
                  [ngxTimepicker]="toggleIcon"
                  [min]="currentTime"
                />
                <ngx-material-timepicker-toggle [for]="toggleIcon">
                  <svg
                    class="timeicon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    width="20px"
                    height="20px"
                    ngxMaterialTimepickerToggleIcon
                  >
                    <path
                      d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,16H7.995 C7.445,16,7,15.555,7,15.005v-0.011C7,14.445,7.445,14,7.995,14H14V5.995C14,5.445,14.445,5,14.995,5h0.011 C15.555,5,16,5.445,16,5.995V16z"
                    />
                  </svg>
                </ngx-material-timepicker-toggle>
                <ngx-material-timepicker #toggleIcon></ngx-material-timepicker>
              </div>
              <label class="info d-flex mb-0">
                <mat-icon
                  tooltip="Please note, that the earliest you can schedule a card to be sent is 30 minutes from now.  This will allow you to complete the payout."
                >
                  info
                </mat-icon>
              </label>
            </div>
          </div>
        </div>

        <div
          *ngIf="
            form.get('automaticCardEmail')?.value === 'manually' &&
            !form.get('finalTemplate')?.value &&
            form.get('templateType')?.value != 'freeCard' &&
            campaignId
          "
        >
          <a class="skyblue a" (click)="copyCardLink()">Copy card link</a>
        </div>
      </div>

      <div
        class="alert alert-success d-flex col-lg-6"
        role="alert"
        *ngIf="campaign?.isAmountProcessed"
      >
        <mat-icon class="mr-3">check</mat-icon> Payment successfully processed
      </div>
    </mat-card>
    <div
      class="actions"
      *ngIf="
        (isExpried || campaign.status !== 'active') &&
        !campaign?.isAmountProcessed
      "
      [ngSwitch]="form.get('payoutType').value"
    >
      <button
        mat-ripple
        *ngSwitchCase="'owngift'"
        class="mr-3 orange"
        [disabled]="
          !campaign?.collectedAmount ||
          buttonDisable ||
          this.form.get('cardRecipientEmail').value === ''
        "
        size="lg"
        type="button"
        ButtonUi
        color="c1"
        (click)="openCharityDialogOwnGift()"
      >
        Proceed
      </button>

      <button
        mat-ripple
        *ngSwitchCase="'catalogue'"
        class="mr-3 orange"
        [disabled]="!campaign?.collectedAmount || buttonDisable "
        size="lg"
        type="button"
        ButtonUi
        color="c1"
        (click)="openCharityDialog()"
      >
        <!-- {{ buttonDisable }} -->
        Proceed to choose Gift
      </button>

      <!-- <button ButtonUi class="orange" color="c1" size="lg" [loading]="loading" [disabled]="loading || buttonDisable"
        (click)="update()">
        Save and go to dashboard
      </button> -->
    </div>
    <div
      class="actions"
      *ngIf="
        (!isExpried && campaign.status === 'active') ||
        campaign?.isAmountProcessed
      "
    >
      <button
        class="orange"
        ButtonUi
        color="c1"
        size="lg"
        routerLink="/pages/detail/campaigns"
      >
        go to dashboard
      </button>
    </div>
  </form>
</div>
<!-- <div class="p-3">
  purchaseCard : {{ form.get("purchaseCard").value }} <br />
  decidedGift : {{ form.get("decidedGift").value }} <br />
  isGiftBought : {{ form.get("isGiftBought").value }} <br />
  giftAmount: {{ form.get("giftAmount").value }} <br />
  remainingAmount: {{ remainingAmount }} <br />
  wantToDonate : {{ form.get("wantToDonate").value }} <br />
  charityAmount: {{ form.get("charityAmount").value }} <br />
  userAmount: {{ form.get("userAmount").value }} <br />
  cardAmount: {{ form.get("cardAmount").value }} <br />
</div> -->
