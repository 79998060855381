import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Campaign } from 'src/app/models/campaign.model';
import { AppService } from 'src/app/services/app.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { BuyGiftCardComponent } from '../../components/buy-gift-card/buy-gift-card.component';
import { CharityDialogComponent } from '../charity-dialog/charity-dialog.component';
const AMOUNT_PATTERN = /^((^((?!(0))[0-9]*)$)|((0)?([1-9]*)\.[0-9]*)?)?$/i;

@Component({
  selector: 'app-donate-charity-dialog',
  templateUrl: './donate-charity-dialog.component.html',
  styleUrls: ['./donate-charity-dialog.component.scss'],
})
export class DonateCharityDialogComponent implements OnInit {
  cardPrice: any;
  giftAmount: number;
  isWantToDonate: boolean = false;
  isChecked: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<DonateCharityDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      this: BuyGiftCardComponent;
      payoutType: any;
      cardVal: any;
      charityType: string;
      sendType: boolean;
    },
    public _appService: AppService,
    public _dialog: MatDialog,
    public configurationService: ConfigurationService
  ) {
    this.data;
    if (data.cardVal == 'uploaded') {
      data.this.campaignCardPr = 4;
    }
    this.cardPrice = data?.this?.campaignCardPr;
  }

  ngOnInit() {
    this.form
      .get('giftAmount')
      ?.setValidators([
        Validators.max(this.data?.this.campaign?.collectedAmount),
      ]);
    // if (this.data?.this?.cardVal == 'uploaded') {
    //   this.giftAmount =
    //     this.data?.this?.campaign?.collectedAmount -
    //     (this.data?.this?.money || 0) -
    //     4;
    //   this.form.controls.giftAmount.setValue(this.giftAmount);
    // }
    // if (this.data?.this?.cardVal == 'selected') {
    //   this.giftAmount =
    //     this.data?.this?.campaign?.collectedAmount -
    //     (this.data?.this.money || 0) -
    //     this.data?.this.campaignCardPr;
    //   this.form.controls.giftAmount.setValue(this.giftAmount);
    // }
    // if (this.data?.this?.cardVal == 'freeCard') {
    //   this.giftAmount = this.data?.this?.campaign?.collectedAmount;
    //   this.form.controls.giftAmount.setValue(this.giftAmount);
    // }
    this.reset();
  }

  public get campaignContent() {
    return this.configurationService?.campaignContent || {};
  }

  getNumberAfterDecimal(num: number) {
    if (Number.isInteger(num)) {
      return 0;
    }
    const decimalStr = '0' + '.' + num.toString().split('.')[1];
    return Number(decimalStr);
  }

  get form(): FormGroup {
    return this.data.this.form;
  }

  get campaign(): Campaign {
    return this.data.this.campaign;
  }

  get remainingAmount() {
    return this.data.this.remainingAmount;
  }

  get campaignCardPrice() {
    return this.data.this.campaignCardPr;
  }

  public wanttodonate;

  onWantToDonate(v: boolean) {
    this.wanttodonate = v;
    this.isWantToDonate = v;

    this.isChecked = !v;

    if (v == false) {
      this.form.get('giftAmount')?.setValue('');
      this.data.this.money = '';
    }
    this.data.this.onWantToDonate(v);
  }

  onCharityAmountTypeChange(type: 'autoFilled' | 'other') {
    this.data.this.onCharityAmountTypeChange(type);
  }

  reset() {
    this.form.controls.wantToDonate.setValue(true);
    this.onWantToDonate(true);
  }

  onClose(d?: any) {
    if (!d) {
      this.reset();
      this.form?.get('charityAmount')?.setValidators([]);
      this.form?.get('charityAmount')?.updateValueAndValidity();
    }
    this.data.this;
    this.dialogRef.close(d);
  }

  pendingAmount: any;

  valueChanges(e: any) {
    this.pendingAmount = this.campaign?.collectedAmount - (this.cardPrice || 0);
    this.data.this.money = e;

    if (this.data.this.money > this.pendingAmount) {
      // Set validators with max amount and pattern
      this.form
        .get('giftAmount')
        ?.setValidators([
          Validators.pattern(AMOUNT_PATTERN),
          Validators.max(this.pendingAmount),
        ]);

      // Force re-validation of the control
      this.form.get('giftAmount')?.setValue(this.pendingAmount);
      this.form.get('giftAmount')?.updateValueAndValidity();
      this.giftAmount = this.form.get('giftAmount')?.value;
      this.data.this.money = this.form.get('giftAmount')?.value;
      return this._appService.snackBarOpen(
        `This amount exceeds the maximum funds available left to redeem. Please enter an amount less than or equal to the total amount left to redeem.`
      );
    } else {
      this.giftAmount = this.form.get('giftAmount')?.value;
    }
  }

  OpenCharityDialog() {
    if (this.wanttodonate) {
      if (this?.data?.charityType === 'payout') {
        this.onClose({
          saveToProceedDialog: true,
          data: 101582,
          isPayout: true,
        });
        return;
      }
      this.onClose({
        saveToProceedDialog: true,
        giftAmount: this.giftAmount,
        donationType: this.wanttodonate,
      });
      return;
    }
    if (this?.data?.charityType === 'payout') {
      this.onClose({
        saveToProceedDialog: true,
        data: 101582,
        isPayout: true,
      });
      return;
    }
    this.onClose({
      isOpenCharityDialog: true,
      data: this.data,
      donationType: this.wanttodonate,
    });
  }

  onChangeChecked(event: Event) {
    this.isChecked = (event.target as HTMLInputElement).checked;
  }
}
