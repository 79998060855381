<div id="container-section" class="container terms pt-5">
  <div class="scroll-top" (click)="scrollToTarget('container-section')">
    <img src="assets/images/arrow-up.svg" alt="" />
  </div>
  <div class="text-center">
    <h1>CollectaGift’s Terms & Conditions</h1>
  </div>
  <section class="mt-0 pt-4">
    <p>Last Updated: 1st January 2025</p>
    <p>
      <b>Welcome to CollectaGift! </b> CollectaGift is an online group gifting
      platform that facilitates online group gift collections by allowing an
      individual (the “Organizer”) to create an online collection pot
      (“CollectaGift Pot”) and invite a group of individuals (“Contributors”) to
      contribute to the CollectaGift Pot, which the Organizer can redeem to
      proceeds for the purpose of buying a Gift and optional Greeting Card for a
      nominated person (the “Recipient”) on behalf of the Contributions.
      <b>
        Please read these Terms & Conditions carefully before using our service.
        By using our service you accept our Terms & Conditions.</b
      >
      <br />

      These Terms and Conditions contain the following key sections. Whilst we
      request you read all the contents prior to using our Site, by clicking on
      a section in the list below, you can jump straight to the specific
      section.
    </p>
    <div class="contents pt-4 mb-0">
      <p class="m-0"><b>​​Contents </b></p>
      <p (click)="scrollToTarget('about')" class="m-0 scroll-to-click">
        ​​1 About our Terms
      </p>
      <p (click)="scrollToTarget('touch')" class="m-0 scroll-to-click">
        ​​2 Getting in touch, Disputes and Complaints
      </p>
      <p (click)="scrollToTarget('d')" class="m-0 scroll-to-click">
        ​​3 Definitions
      </p>
      <p (click)="scrollToTarget('user')" class="m-0 scroll-to-click">
        ​​4 Becoming a Registered User
      </p>
      <p (click)="scrollToTarget('inviting')" class="m-0 scroll-to-click">
        ​​5 Creating a CollectaGift Pot and Inviting People To Contribute
      </p>
      <p (click)="scrollToTarget('contributing')" class="m-0 scroll-to-click">
        ​​6 Contributing to a CollectaGift Pot
      </p>
      <p (click)="scrollToTarget('redeeming')" class="m-0 scroll-to-click">
        ​​7 Redeeming a CollectaGift Pot
      </p>
      <p (click)="scrollToTarget('other')" class="m-0 scroll-to-click">
        ​​8.0 Other General Terms
      </p>
    </div>
    <blockquote class="pl-4">
      <p (click)="scrollToTarget('site')" class="m-0 scroll-to-click">
        ​ ​8.​1 Use of the Site:
      </p>
      <p (click)="scrollToTarget('info')" class="m-0 scroll-to-click">
        ​ 8.​​2 Your privacy and personal information
      </p>
      <p (click)="scrollToTarget('ownership')" class="m-0 scroll-to-click">
        ​ 8.​​3 Ownership, use and intellectual property rights
      </p>
      <p (click)="scrollToTarget('accuracy')" class="m-0 scroll-to-click">
        ​ 8.​​4 Accuracy of information and availability of the Site
      </p>
      <p (click)="scrollToTarget('hyperlink')" class="m-0 scroll-to-click">
        ​ 8.​​5 Hyperlinks nad third party sites
      </p>
      <p (click)="scrollToTarget('limitation')" class="m-0 scroll-to-click">
        ​ 8.​​6 Limitation on our liability
      </p>
      <p (click)="scrollToTarget('variation')" class="m-0 scroll-to-click">
        ​ 8.​​7 Variation
      </p>
      <p (click)="scrollToTarget('disputes')" class="m-0 scroll-to-click">
        ​ 8.​​8 Disputes & Complaints
      </p>
    </blockquote>

    <p id="about" class="pt-4">
      <b>1 About our Terms</b><br />
      1.1 These Terms explain how you may use this website (the Site). These
      Terms also explain what we do for you, what you need to do, and how the
      process of making a gift is handled.
      <br />
      1.2 References in these Terms to the Site includes the following websites:
      www.CollectaGift.com and all associated web pages. <br />
      1.3 You should read these Terms carefully before using the Site. <br />
      1.4 By accessing or using the Site or otherwise indicating your consent,
      you agree to be bound by these Terms and the documents referred to in
      them. <br />
      1.5 If you do not agree with or accept any of these Terms, you should stop
      using the Site immediately.
    </p>
    <p id="touch" class="pt-4">
      <b>2 Getting in touch, Disputes and Complaints</b> <br />
      If you have any questions about the Site, would like to make a Complaint
      or raise a dispute; or would otherwise like to get in touch with us about
      our Services, please contact us at
      <a style="color: #3c49bf; font-weight: 600"
        >hello&#64;collectagift.com.</a
      >
      and we will aim to get back to you as quickly as possible.
    </p>

    <p id="definitions" class="pt-4">
      <b>3 Definitions </b> <br />

      <b>Content :</b>  means any text, images, video, audio or other multimedia
      content, software or other information or material submitted to or on the
      Site; <br />
      <b>User Content :</b>  means any text, images, video, audio or other
      multimedia content, software or other information or material submitted to
      the Site by you; <br />
      <b>Site :</b>  means the website www.collectagift.com and all associated
      web pages <br />
      <b>Terms :</b>  means these terms and conditions of use as updated from
      time to time <br />
      <b>we :</b>  means CollectaGift Limited, company registration number
      12763692, and the registered office of which is at 86 Ashurst Road Barnet
      EN4 9LG (and us or our shall have the same meaning); <br />
      <b>you :</b>  means the person accessing or using the Site or its Content
      (and your shall have the same meaning). <br />
      <b>CollectaGift Pot:</b>  means a virtual collection pot created by the
      Organizer as a means of pooling Contributions intended to fund a gift (or
      gifts) for the Recipient. <br />
      <b>Contributions :</b>  means monetary contributions made towards a
      CollectaGift pot. <br />
      <b>Organizer :</b>  means the person using the Site to create a
      CollectaGift Pot. This must be an individual and not a company. An
      Organizer needs to be a Registered User.<br />

      <b>Contributor :</b>  means any person using the Site to make
      Contributions. A contributor can be a Registered user but an individual
      can also be a Contributor without being a Registered User. <br />
      <b>Recipient :</b>  means the person(s) intended to receive the gift (or
      gifts) funded by a CollectaGift pot. <br />

      <b>Payment Processing Fees: </b> means any fees charged by our payment
      provider Stripe for the purposes of processing your Contribution. We use
      Stripe as our payment provider. Stripe charges Payment Processing Fees
      which we pass on and which can either be added to or deducted from a
      Contribution. <br />

      <b>Contribution:</b> means the amount specified by the Contributor to be
      paid into the CollectaGift Pot. Payment Processing fees can be added to or
      deducted from this amount as directed by the Contributor and therefore
      this amount may differ from the Contribution amount. <br />

      <b>Contribution Proceeds:</b> means the amount added into a CollectaGift
      Pot as a result of a Contribution. This may be less than the Contribution
      amount if the Contributor opts to deduct Payment Processing Fees from
      their Contribution. Amount Payable: means the amount charged to a
      Contributors payment card as a result of a Contribution. This may be same
      as the Contribution amount, if Payment Processing fees are deducted from
      the Contribution amount or may be higher than the Contribution amount if
      the Payment Proceeds are added to the Contribution Amount. <br />

      <b>Spare Change:</b> Spare Change is any residual money in the
      CollectaGift Pot, that the organizer may choose to donate to our charity
      partner, Smile Train. <br />
    </p>

    <p id="user" class="pt-4">
      <b> 4. Becoming a Registered User</b> <br />

      4.1 In order to create a CollectaGift Pot as an Organizer you must be a
      Registered User of the Site. Upon creating a CollectaGift Pot, if you are
      not logged in a Registered User the Site will prompt you to login or to
      sign up as a new Registered User. As a Contributor, you are also able to
      sign up as a Registered User although a Registered User account isn’t
      mandatory to be able to make a Contribution.
      <br />
      4.2 To sign up as a Registered User you must be at least 18 years of age;
      reside in the United Kingdom, not currently have another Registered User
      account with us and acknowledge and accept these Terms & Conditions as
      part of the sign up process.

      <br />
      4.3 If you no longer meet the above eligibility criteria to be a
      Registered User you must notify us immediately.

      <br />
      4.4 As a Registered User you are responsible for ensuring the security of
      your account by using a strong password, which you must maintain the
      confidentiality of. Do not share this password with anyone and we
      recommend you use a unique password.

      <br />
      4.5 If you suspect any unauthorized use of your account, you should
      immediately change your password and contact us (using the details
      provided above) to discuss any ways in which we can assist, however we
      will not be held liable for any fraudulent activity on your Registered
      User account.
    </p>

    <p id="inviting" class="pt-4">
      <b>5 Creating a CollectaGift Pot and Inviting People to Contribute</b>

      <br />
      5.1 In order to create a CollectaGift Pot as an Organizer, you need to be
      a Registered User.

      <br />
      5.2 An Organizer creates a CollectaGift Pot by clicking on the ‘Start an
      Online Collection’ button and completing the presented fields and
      uploading User Content to present an image relevant to the
      intention/purpose of the gift. The Organizer can either specify an exact
      gift to be funded, provide an idea of the type of gift to be funded, or
      request gift ideas from Contributors. Alternatively, there is also the
      option for the Organizer to send the Recipient a link, whereby the
      Recipient can access the link and choose a Gift from our Gift Catalogue
      themselves.

      <br />
      5.3 If the Organizer is not logged in as a Registered User at the point of
      creating a CollectaGift Pot, the Site will prompt the Organizer to either
      log in or register with the Site.

      <br />
      5.4 Once the CollectaGift Pot has been created, a link is generated and
      this is made available to the Organizer. The Organizer may then forward
      that link to Contributors who, upon following the link, will be able to
      make Contributions towards the specific CollectaGift Pot the link relates
      to.

      <br />
      5.5 By creating a CollectaGift Pot, the Organizer represents and warrants
      that they are setting up a CollectaGift Pot for the sole purpose
      facilitating an online group gift collection for the nominated Recipient.
    </p>

    <p id="contributing" class="pt-4 m-0">
      <b>6 Contributing to a CollectaGift Pot </b>

      <br />
      6.1 In order to contribute to a CollectaGift pot a Contributor can be
      logged in as a Registered User but they can also contribute without being
      a Registered User.

      <br />
      6.2 If a Contributor is not a Registered User they will be required to
      enter their name.

      <br />
      6.3 You can contribute money to a CollectaGift Pot by following the link
      sent to you by the Organizer of the CollectaGift pot, or directly via your
      Registered Account if you are also the Organizer of the CollectaGift Pot.

      <br />
      6.4 In order to contribute to a CollectaGift Pot, you will be required to
      enter credit/debit card details as requested by Stripe, our payment
      provider. These details will be securely processed by Stripe and will not
      be made available to CollectaGift Ltd.

      <br />
      6.5 By making payment, you represent and warrant that you are authorized
      to use the payment method you have selected.

      <br />
      6.6 By making a payment, you acknowledge and accept that your payment will
      be processed by Stripe.

      <br />
      6.7 By making a contribution, you accept that Stripe’s Payment Processing
      Fees will be passed onto you, the Contributor. The Payment Processing Fee
      will differ depending on the location/currency of the Contributions.

      <br />
      6.8 Regarding incurring the Payment Processing Fees, the Contributor will
      be able to choose between one of the following options:
    </p>
    <blockquote class="m-0 pl-4">
      (a) the Contributor will be able add the Payment Processing Fees to the
      Contribution amount, in which case the Amount Payable will exceed the
      Contribution amount but the Contribution proceeds will be the same as the
      Contribution amount; or
      <br />
      (b) the Contributor will be able to deduct the Payment Processing Fees
      from their Contribution amount, in which case the Amount Payable will be
      the same as their Contribution amount but the Contribution proceeds will
      be less.
    </blockquote>
    <p class="m-0">
      6.9 All contributions are final and cannot be refunded.

      <br />
      6.10 Upon successful payment we will endeavor to issue a payment
      confirmation receipt via email. However we will not bear any liability
      from the failure to issue a payment receipt.

      <br />
      6.11 Once a Contribution has been successfully processed, the Contribution
      Proceeds will be immediately credited to the specific CollectaGift Pot.

      <br />
      6.12 Once payment has been successfully made for a Contribution, the
      Contributor will be prompted to enter a greeting for the Recipient to be
      included on a Group Greeting Card which the Organizer can choose to send
      to the Recipient upon redemption of the CollectaGift Pot. In case the
      Contributor fails to add a greeting message once payment has been made,
      CollectaGift will not be held liable.

      <br />
      6.13 We offer no guarantee and do not represent or warrant that your
      Contributions will be used for any specific purpose. The Organizer will
      have the option when creating a CollectaGift Pot to either specify an
      exact gift to be funded, or to provide an idea of the type of gift to be
      funded before you agree to make a Contribution. However it is entirely at
      the Organizer’s discretion what gift is purchased. We shall not be
      responsible for any dissatisfaction a Contributor may have in relation to
      any gift (or gifts) purchased for the Recipient using your Contributions.
      Once you have made your Contribution, any discussions/disagreements about
      the use of your Contribution will be between you and the Organizer.

      <br />
      6.14 The Contributor acknowledges and accepts that at the redemption
      stage, the Organizer may choose to donate any Spare Change to our partner
      charity, Smile Train.
    </p>

    <p id="redeeming" class="pt-4">
      <b>7 Redeeming a CollectaGift Pot</b>

      <br />
      7.1 Once the deadline for Contributions in relation to a specific
      CollectaGift Pot has expired, the Organizer will redeem the Collection
      Proceeds to purchase a gift and optional greeting card for the nominated
      Recipient. There will also be the option for the Organizer to donate any
      Spare Change to our nominated charity partner, Smile Train.

      <br />
      7.2 There are two main redemption options for the Organizer to choose
      from: “Choose Voucher from Gift Catalogue” or “Allow Recipient to Choose
      Gift”. Under “Choose Voucher from Gift Catalogue”, they can either choose
      a specific Gift Card from our Gift Catalogue to gift directly to the
      Recipient, or use the Gift Card themselves to purchase a gift for the
      Recipient from the selected merchant in our Gift Catalogue. Under “Allow
      Recipient to Choose Gift”, the Organizer can generate a link which they
      can send directly to the Recipient for the Recipient to choose a specific
      voucher from our Gift Catalogue themselves.

      <br />
      7.3 If a paid greeting card is chosen, the cost of the Greeting Card will
      be deducted from the Collection Proceeds, leaving Available Funds as the
      amount available to redeem.

      <br />
      7.4 The Organizer will be able to choose whether to spend all Available
      Funds to purchase the Gift or whether to donate any Spare Change to Smile
      Train, our nominated Charity Partner. Available funds is the amount
      available to redeem after the cost of the optional greeting card has been
      deducted. Therefore, the Redemption Amount may be less than the Available
      Funds.

      <br />
      7.5 Once the Organizer has confirmed the Redemption Amount, they will be
      able to purchase an e-voucher from one of the Merchants in our Gift
      Catalogue or generate the Redeem Link, depending on which Redemption
      Option is chosen.

      <br />
      7.6 Our Gift Catalogue is powered by our Gift Provider and the purchase of
      any Product via our platform is subject to our Gift Provider’s separate
      terms, conditions and limitations. These are displayed in our Gift
      Catalogue however we will not be held liable for any misrepresentation by
      our Gift Provider.

      <br />
      7.7 We cannot guarantee that a Gift Voucher from your chosen Merchant will
      be available on our platform at all times and we reserve the right to
      withdraw or change the Gift Vouchers and Merchants available on our
      platform at any time and without prior notification to you.

      <br />
      7.8 By purchasing a voucher from our Gift Catalogue, you acknowledge and
      agree that our Gift Provider (s) will be granted access to your personal
      data, to the extent that such data is necessary to fulfil their
      contractual obligations to you. The Gift Provider(s) will also have their
      own privacy policy which you should review for further information about
      how they use, share and protect your personal data.

      <br />
      7.9 The digital Greeting Card and E-Voucher/Redeem Link will be dispatched
      to the respective email addresses entered by the Organizer. It is the
      responsibility of the Organizer to ensure that the input email addresses
      are correct. We cannot be responsible for the incorrect delivery of
      digital Greeting Cards or e-Vouchers purchased from our Gift Catalogue.
    </p>

    <p id="other" class="pt-4 m-0">
      <b>8.0 Other General Terms </b> <br />
      <br />

      <b id="site">8.1 Use of the Site: </b> <br />

      8.1.1 The Site is for your personal and use only. As a condition of your
    </p>
    <blockquote class="pl-4 m-0">
      (a) for any purpose that is unlawful under any applicable law or
      prohibited elsewhere in these Terms; <br />
      (b) to commit any act of fraud; <br />
      (c) to distribute viruses or malware or other similar harmful software
      code; <br />
      (d) for purposes of promoting unsolicited advertising or sending spam ;
      <br />
      (e) to simulate communications from us or another service or entity in
      order to collect identity information, authentication credentials, or
      other information (‘phishing’);
      <br />
      (f) in any manner that disrupts the operation of our Site or business or
      the website or business of any other entity; <br />
      (g) in any manner that harms minors; <br />
      (h) to promote any unlawful activity; <br />
      (i) to represent or suggest that we endorse any other business, product or
      service unless we have separately agreed to do so in writing; <br />
      (j) to gain unauthorized access to or use of computers, data, systems,
      accounts or networks; <br />
      (k) to attempt to circumvent password or user authentication methods.
    </blockquote>
    <p class="m-0">
      8.1.2 The Site is intended for use primarily by those who can access it
      from within the UK. If you choose to access the Site from locations
      outside the UK, you are responsible for compliance with local laws where
      they are applicable.

      <br />
      8.1.3 We seek to make the Site as accessible as possible. If you have any
      difficulties using the Site, please contact us at
      hello&#64;CollectaGift.com.

      <br />
      8.1.4 We may prevent or suspend your access to the Site if you do not
      comply with any part of these Terms, any terms or policies to which they
      refer or any applicable law. Access to certain areas of the Site is
      restricted. We reserve the right to further restrict access to other areas
      of the Site (or the entire Site) at our discretion.

      <br />
      <br />
      <b id="info">8.2 Your privacy and personal information</b><br />

      Your privacy and personal information are important to us. Any personal
      information that you provide to us will be dealt with in line with our
      privacy policy, which explains what personal information we collect from
      you, how and why we collect, store, use and share such information, your
      rights in relation to your personal information and how to contact us and
      supervisory authorities in the event you have a query or complaint about
      the use of your personal information. Our privacy policy is available at
      www.CollectaGift.com/privacy-policy.

      <br />
      <br />

      <b id="ownership">8.3 Ownership, use and intellectual property rights </b
      ><br />

      All information, data and copyright material contained in our Services,
      our platform and website, including any domain names, trade marks (whether
      registered or unregistered), trade names, brands and logos belong to us or
      its licensors who have given us permission to use them. We and our
      licensors are and shall remain the owner or licensee of all intellectual
      property rights in our Services and the material or content published on
      them. Those works are protected by copyright laws and treaties around the
      world. All such rights are reserved. Unless expressly provided under these
      Terms, we do not grant to you any rights in any of our Services which
      shall at all times remain the sole and exclusive property of us or our
      licensors.  

      <br />
      <br />
      Any material or content you upload via our Services (whether as the
      Organizer or a Contributor) which is displayed on our platform will be
      considered non-confidential and non-proprietary. You retain all of your
      ownership rights in your content or material, but when you upload your
      content you grant us a worldwide, non-exclusive, royalty-free,
      transferable, sub-licensable license to use your content, including to
      reproduce (e.g. copy), adapt, make derivative works (e.g. to translate
      and/or create captions), perform and communicate your content to the
      public (e.g. to display it), for the purposes of operating, developing,
      marketing and providing our Services.  

      <br />
      <br />
      When using the Site you must ensure that any User Content you provide does
      not breach any intellectual property rights of a third party or breach any
      right or duty owed to a third party. Please ensure that any User Content
      you upload to the Site, especially pictures or photographs, does not
      infringe another person’s copyright.

      <br />
      <br />
      If you include an image on the Site, you must ensure that you either own
      the copyright to that image or that you are fully licensed by the
      copyright owner to include that image on the Site. We reserve the right to
      remove any pictures, photographs or copy from the Site at our sole
      discretion and without notice if their copyright status is in doubt. If we
      find offensive, defamatory, libellous, threatening, harassing, or obscene
      User Content uploaded onto the Site, we will remove it and terminate your
      access.
    </p>
    <blockquote class="pl-4">
      8.3.1 Nothing in these Terms grants you any legal rights in the Site other
      than as necessary to enable you to access the Site. You agree not to
      adjust to try to circumvent or delete any notices contained on the Site
      (including any intellectual property notices) and in particular in any
      digital rights or other security technology embedded or contained within
      the Site.
    </blockquote>
    <p class="m-0">
      <b id="accuracy"
        >8.4 Accuracy of information and availability of the Site
      </b>
      <br />

      8.4.1 While we try to make sure that the Site is accurate, up-to-date and
      free from bugs, we cannot promise that it will be. Furthermore, we cannot
      promise that the Site will be fit or suitable for any purpose. Any
      reliance that you may place on the information on the Site is at your own
      risk.

      <br />
      8.4.2 We may suspend or terminate operation of the Site at any time as we
      see fit.

      <br />
      8.4.3 Content is provided for your general information purposes only and
      to inform you about us and our products and news, features, services and
      other websites that may be of interest. It does not constitute technical,
      financial or legal advice or any other type of advice and should not be
      relied on for any purposes. You should not rely on information given on
      the Site or its associated services to determine any consequences of
      making a Contribution

      <br />
      8.4.4 While we try to make sure that the Site is available for your use,
      we do not promise that the Site is available at all times nor do we
      promise the uninterrupted use by you of the Site.

      <br />
      <br />
      <b id="hyperlink">8.5 Hyperlinks and third party sites </b>
      <br />
      The Site may contain hyperlinks or references to third party websites
      other than the Site. Any such hyperlinks or references are provided for
      your convenience only. We have no control over third party websites and
      accept no legal responsibility for any content, material or information
      contained in them. The display of any hyperlink and reference to any third
      party website does not mean that we endorse that third party’s website,
      products or services. Your use of a third party site may be governed by
      the terms and conditions of that third party site.

      <br />
      <br />
      <b id="limitation">8.6 Limitation on our liability </b>
      <br />

      8.6.1 The Platform shall not be held liable to the Users for errors,
      omissions, interruptions or delays of the transactions executed through
      the Website or resulting from an unauthorized access to the Website.
      Moreover, the Platform shall not be held liable for thefts, destructions
      or unauthorized communications of data resulting from unauthorized access
      to the Website.

      <br />
      We shall have no liability to you for any breach of these Terms caused by
      any event or circumstance beyond our reasonable control including, but not
      limited to, acts of God, governmental action, war or national emergency,
      riot, civil commotion, fire, flood, explosion or accident, inclement
      weather, pandemic, lock outs, strikes or other labour disputes.

      <br />
      8.6.2 The Platform retains the right to temporarily suspend the online
      access to the Account for technical or maintenance reasons without any
      compensation.

      <br />
      8.6.3 The Platform undertakes to do its best to ensure the safety and the
      confidentiality of the data exchanged under the use of the Website in
      accordance with the Privacy Policy, while the Platform is in charge of the
      security and the confidentiality of data it hereby exchanges with the User
      regarding the creation and the management of the Account and the
      Transactions concerning this Account.

      <br />
      8.6.4 If you breach these Terms and it creates costs for us, we may
      recover those costs from you. This could include the costs of tracing you
      and taking steps to deal with the issue, enforcing the payment of any
      money you owe us or our Payment Services Providers, or the costs of
      third-party claims against us arising from you breaking these Terms. We
      may also charge for communicating with you about what has happened. 

      <br />
      <br />
      <b id="variation">8.7 Variation</b>
      <br />
      These Terms are dated 1st November 2024. No changes to these Terms are
      valid or have any effect unless agreed by us in writing. We reserve the
      right to vary these Terms from time to time. Our updated Terms will be
      displayed on the Site and by continuing to use and access the Site
      following such changes, you agree to be bound by any variation made by us.
      It is your responsibility to check these Terms from time to time to verify
      such variations.
      <br />
      <br />
      <b id="disputes">8.8 Disputes & Complaints</b>
      <br />
      8.8.1 We will try to resolve any disputes with you quickly and
      efficiently. <br />
      8.8.2 If you are unhappy with us please contact us as soon as possible at
      hello&#64;collectagift.com <br />
      8.8.3 If you and we cannot resolve a dispute using our complaint handling
      procedure, we will let you know that we cannot settle the dispute with
      you; and give you certain information required by law about alternative
      dispute resolution. <br />
      8.8. 4 If you want to take court proceedings, the relevant courts of the
      United Kingdom will have exclusive jurisdiction in relation to these
      Terms. <br />
    </p>
  </section>
</div>
