import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormArray,
  Validators,
  FormControl,
  EmailValidator,
  ValidatorFn,
} from '@angular/forms';
import { CampaignService } from 'src/app/services/campaign.service';
import { AppService } from 'src/app/services/app.service';
import { Campaign } from 'src/app/models/campaign.model';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Subscription } from 'rxjs';
import { giftSuggestion } from 'src/app/models/gift-suggestion.model';
import * as moment from 'moment';
import { ValidationService } from '../shareModule/controls-msg/validation.service';

const LINK_PATTERN =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i;

const AMOUNT_PATTERN = /^((^((?!(0))[0-9]*)$)|((0)?([1-9]*)\.[0-9]*)?)?$/i;

@Component({
  selector: 'app-create-gift',
  templateUrl: './create-gift.component.html',
  styleUrls: ['./create-gift.component.scss'],
})
export class CreateGiftComponent implements OnInit {
  tabs = [
    { img: 'genral', title: 'Create the CollectaGift Pot', isActive: true },
    {
      img: 'Redeem icon',
      title: 'Invite People to Contribute',
      isActive: false,
    },
    { img: 'card 2', title: 'Buy Gift and Card', isActive: false },
  ];

  isLoading: boolean = false;
  campaign = new Campaign();
  campaignId: any;
  subscription: Subscription;

  form: FormGroup = new FormGroup(
    {
      giftSuggestions: new FormArray([], [Validators.required]),
      receipientName: new FormControl('', [Validators.required]),
      receipientImgUrl: new FormControl(''),
      campaignCurrency: new FormControl('GBP', Validators.required),
      message: new FormControl('', [Validators.required]),
      collectionDeadline: new FormControl('', [Validators.required]),
      sendEmailReminders: new FormControl(true, [Validators.required]),
      addStoryFromContributors: new FormControl(true, [Validators.required]),
      giftDecisionType: new FormControl('', [Validators.required]),
      giftLink: new FormControl('', [ValidationService.urlValidator]),
      giftName: new FormControl('', [
        Validators.required,
        ValidationService.spaceValidator,
      ]),
      maxContributionAmountInTotal: new FormControl('', [
        Validators.pattern(AMOUNT_PATTERN),
      ]),
      giftPollExpiryDate: new FormControl('', [Validators.required]),
      purposeOfGift: new FormControl(''),
      categoryId: new FormControl('', [Validators.required]),
      receipientGender: new FormControl('', [Validators.required]),
      receipientAge: new FormControl('', [Validators.required]),
      particularInterests: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z ]$'),
      ]),
      minimumContributionAmountPerPerson: new FormControl('', [
        Validators.required,
        Validators.pattern(AMOUNT_PATTERN),
      ]),
      purchaseCard: new FormControl('', [Validators.required]),
      giftAmount: new FormControl('', [Validators.pattern(AMOUNT_PATTERN)]),
      // redeemOrDonate: new FormControl('redeem', [Validators.required]),
      receipientEmail: new FormControl('', [ValidationService.emailValidator]),
      selectedTemplateUrl: new FormControl('', [Validators.required]),
      accountHolderName: new FormControl(''),
      accountNumber: new FormControl(''),
      routingNumber: new FormControl(''),
      country: new FormControl(''),
      bankName: new FormControl(''),
      cardAmount: new FormControl(''),
      userAmount: new FormControl(''),
      invitedEmails: new FormArray([]),
      uniqueCode: new FormControl('', [Validators.required]),
      charityAmount: new FormControl(''),
      charityId: new FormControl(''),
      finalTemplate: new FormControl('', [Validators.required]),
      inviteLink: new FormControl(''),
      completedFinalStep: new FormControl(''),
      automaticCardEmail: new FormControl(false),
      isAutoEmailToRecipient: new FormControl(true),
      sendToRecipientEmail: new FormControl(true),

      cardEmailTime: new FormControl(''),
      cardSendEmailDate: new FormControl(''),
      cardSendEmailTime: new FormControl(''),
      isGiftBought: new FormControl(false),
      decidedGift: new FormControl(''),
      wantToDonate: new FormControl(''),
      charityAmountType: new FormControl(''),
      sendDeadlineReminder: new FormControl(true),
      cardRecipientEmail: new FormControl('', [
        ValidationService.emailValidator,
      ]),

      comment: new FormControl('', [ValidationService.spaceValidator]),
      stripeCharges: new FormControl(0),
      payoutType: new FormControl('catalogue'),
      cardType: new FormControl('digital'),
      templateType: new FormControl('uploaded'),
      confirmEmail: new FormControl('', [ValidationService.emailValidator]),
    },
    {
      validators: [
        ValidationService.compareEmails('cardRecipientEmail', 'confirmEmail'),
      ],
    }
  );

  constructor(
    private _campaignService: CampaignService,
    private _appService: AppService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    this.subscription = this._activatedRoute.queryParams.subscribe((d) => {
      if (d['campaignId'] && !this.campaign.id) {
        this.campaignId = d['campaignId'];
        this.fetch(true);
      }
    });

    this.form.get('collectionDeadline').valueChanges.subscribe((d) => {
      this.form.get('giftPollExpiryDate').setValue(d);
    });

    this.form.get('giftDecisionType').valueChanges.subscribe((d) => {
      if (d === 'decided') {
        this.form.get('decidedGift').setValue(true);
      }
      [
        ...this.giftDecisionDecided,
        ...this.giftDecisionSuggestionFromContributors,
        ...this.giftDecisionSuggestionPoll,
      ].forEach((k) => this.resetControl(k, false));
      if (!d) return;
      this.giftDecisionTypeOption(d).forEach((k) => {
        this.resetControl(k, true);
        if (k == 'giftSuggestions' && this.campaign[k]) {
          this.addGiftIdeas(this.campaign[k]);
        } else if (this.campaign[k] !== undefined && this.campaignId) {
          this.form.get(k).setValue(this.campaign[k]);
        }
      });
    });

    this.form.get('purchaseCard').valueChanges.subscribe((d) => {
      this.purchasedGiftOptions.forEach((k) => this.resetControl(k, d));
    });

    // this.form.get('branchName').valueChanges.subscribe(d => {
    //   if(d && !/[a-zA-Z]+/i.test(d)){
    //     this.form.get('branchName').setErrors({invalid:'invalid'});
    //   }
    // })

    this.form.get('cardSendEmailDate').valueChanges.subscribe((d) => {
      if (d) {
        const time = this.form.get('cardSendEmailTime').value;
        if (time) {
          const dateTime = moment(`${moment(d).format('L')} ${time}`, [
            'MM/DD/YYYY hh:mm A',
          ]).toISOString();
          this.form.get('cardEmailTime').setValue(dateTime);
        } else {
          this.form.get('cardSendEmailTime').setErrors({ invalid: true });
        }
      } else {
        this.form.get('cardSendEmailDate').setErrors({ invalid: true });
      }
    });

    this.form.get('cardSendEmailTime').valueChanges.subscribe((d) => {
      if (d) {
        const date = this.form.get('cardSendEmailDate').value;
        if (date) {
          const dateTime = moment(`${moment(date).format('L')} ${d}`, [
            'MM/DD/YYYY hh:mm A',
          ]).toISOString();
          this.form.get('cardEmailTime').setValue(dateTime);
        } else {
          this.form.get('cardSendEmailDate').setErrors({ invalid: true });
        }
      } else {
        this.form.get('cardSendEmailTime').setErrors({ invalid: true });
      }
    });
  }

  ngOnInit() {}

  async fetch(loading?: boolean) {
    if (loading) {
      this.isLoading = true;
      this._appService.loadingShow();
    }
    this._campaignService
      .getByInviteLink(this.campaignId)
      .then((d) => {
        // d.isAmountProcessed = false;
        if (d.giftDecisionType === 'decided') {
          d.decidedGift = true;
        }
        this.campaign = { ...d };
        if (!this.campaign.collectedAmount) this.campaign.collectedAmount = 0;
        this._appService.loadingHide();
        this.isLoading = false;
        this.fillForm(d);
      })
      .catch((err) => {
        this._appService.loadingHide();
        this.isLoading = false;
        this._appService.snackBarOpen(err);
        // console.log(err);
      });
  }

  addGiftIdeas(giftSuggestions: giftSuggestion[]) {
    this.giftSuggestions.clear();
    giftSuggestions.forEach((d) => {
      this.giftSuggestions.push(
        new FormGroup({
          id: new FormControl(d.id),
          url: new FormControl(d.url),
          campaignId: new FormControl(d.campaignId),
          loading: new FormControl(false),
        })
      );
    });
  }

  fillForm(data: Campaign) {
    Object.keys(this.form.controls).forEach((k) => {
      if (k == 'giftSuggestions' && data[k] && data[k].length) {
        this.addGiftIdeas(data[k]);
      } else if (k == 'invitedEmails' && data[k] && data[k].length) {
        this.invitedEmails.clear();
        data.invitedEmails.forEach((d) => {
          this.invitedEmails.push(
            new FormGroup({
              id: new FormControl(d.id),
              email: new FormControl(d.email),
              campaignId: new FormControl(d.campaignId),
              loading: new FormControl(false),
            })
          );
        });
      } else if (data[k] != null) {
        this.form.get(k).setValue(data[k]);
      }
    });

    this.form
      .get('giftAmount')
      .setValidators([
        Validators.pattern(AMOUNT_PATTERN),
        Validators.max(data.collectedAmount),
      ]);

    if (
      data['category'] &&
      !data['category'].name.toLowerCase().includes('other')
    ) {
      this.form.get('purposeOfGift').disable();
    }

    if (data['cardEmailTime']) {
      this.form.get('cardSendEmailDate').setValue(data['cardEmailTime']);
      this.form
        .get('cardSendEmailTime')
        .setValue(moment(data['cardEmailTime']).format('hh:mm a'));
    }

    if (this.campaign && this.campaign.id) {
      // this.step1Fields.forEach(k => this.form.get(k).disable());
    }

    if (data.isAmountProcessed) {
      Object.keys(this.form.controls).forEach((d) =>
        this.form.get(d).disable()
      );
    }

    if (this.campaign.giftName) {
      this.form.get('giftName').enable();
      this.form.get('giftName').setValue(this.campaign.giftName);
    }

    this.tabs.forEach((d) => {
      d.isActive = false;
    });
    let qp = this._activatedRoute.snapshot.queryParams;
    this.tabs[qp['tab'] ? qp['tab'] : 0].isActive = true;
  }

  changeTab(i: number) {
    let tab = this.tabs.filter((i) => i.isActive)[0];
    // tab.isCompleted = !notCompleted ? tab.isCompleted : true;
    this.tabs.forEach((i) => (i.isActive = false));
    this.tabs[i].isActive = true;
    this._router.navigate(['/pages/create-gift'], {
      queryParams: { campaignId: this.campaignId, tab: i },
    });
  }

  resetControl(name: string, enable?: boolean) {
    let c = this.form.get(name);
    c.reset();
    c.disable();
    if (enable) c.enable();
  }

  get progress() {
    let progress = Object.keys(this.form.controls).filter(
      (k) => !this.form.get(k).invalid
    ).length;
    return (progress / Object.keys(this.form.controls).length) * 100;
  }

  get invitedEmails(): FormArray {
    return this.form.get('invitedEmails') as FormArray;
  }

  get giftSuggestions(): FormArray {
    return this.form.get('giftSuggestions') as FormArray;
  }

  get purchasedGiftOptions() {
    return ['selectedTemplateUrl', 'finalTemplate'];
  }

  get step1Fields() {
    let common = [
      'receipientName',
      'maxContributionAmountInTotal',
      'categoryId',
      'purposeOfGift',
      'message',
      'collectionDeadline',
      'sendEmailReminders',
      'addStoryFromContributors',
      'giftDecisionType',
      'campaignCurrency',
    ];
    switch (this.form.get('giftDecisionType').value) {
      case 'decided':
        return ['giftLink', 'giftName', ...common];
      case 'suggestionPoll':
        return ['giftSuggestions', ...common];
      case 'suggestionFromContributors':
        // "receipientGender", "receipientAge", 'particularInterests',
        return [...common];
      default:
        return common;
    }
  }

  giftDecisionTypeOption(type) {
    switch (type) {
      case 'decided':
        return this.giftDecisionDecided;
      case 'suggestionPoll':
        return this.giftDecisionSuggestionPoll;
      case 'suggestionFromContributors':
        return this.giftDecisionSuggestionFromContributors;
    }
  }

  get giftDecisionDecided() {
    return ['giftLink', 'giftName'];
  }

  get giftDecisionSuggestionPoll() {
    return ['giftSuggestions'];
  }

  get giftDecisionSuggestionFromContributors() {
    return [];
    // return ["receipientGender", "receipientAge", 'particularInterests',];
  }
}
