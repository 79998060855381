import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CreateCatalogueProductAndProceedComponent } from '../../components/create-catalogue-product-and-proceed/create-catalogue-product-and-proceed.component';
import { AppService } from 'src/app/services/app.service';
import {
  SelectedProductsArray,
  TremendousProduct,
} from 'src/app/models/tremendous.product.model';
import { Campaign } from 'src/app/models/campaign.model';
import { SuccessMessageDialogComponent } from 'src/app/modules/shareModule/dialogs/success-message-dialog/success-message-dialog.component';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/services/campaign.service';

@Component({
  selector: 'app-charity-dialog',
  templateUrl: './charity-dialog.component.html',
  styleUrls: ['./charity-dialog.component.scss'],
})
export class CharityDialogComponent implements OnInit {
  selectedProducts: SelectedProductsArray[] = [
    { item: null, amount: 0 },
    { item: null, amount: 0 },
    { item: null, amount: 0 },
    { item: null, amount: 0 },
  ];
  singleSelectedProducts: SelectedProductsArray[] = [
    { item: null, amount: this.charityAmount() },
  ];
  multiple: true;

  selectedProduct: TremendousProduct;
  isLoading: boolean = false;
  selectedProductIndex: number;
  enteredAmount: number = 0;
  @Input() campaign: Campaign = new Campaign();
  @Output() campaignChange: EventEmitter<Campaign> = new EventEmitter();
  constructor(
    public _appService: AppService,
    private _campaignService: CampaignService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CharityDialogComponent>,
    public _matDialog: MatDialog,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: any; campaign: Campaign; isDonated: boolean }
  ) {}

  ngOnInit(): void {
  }

  callFunction() {
    const viewOnly = false;
    const multiple = false;
    this.chooseByRetailer(viewOnly, multiple);
  }

  public isMultiple = false;
  public max_value = false;

  setMultiple() {
    this.isMultiple = !this.isMultiple;
  }

  onInputChange(e: any, i: number) {
    if (this.totalCardAmount > this.charityAmount()) {
      this._appService.snackBarOpen(
        'You do not have sufficient funds, please review the amount you would like to spend on each retailer.'
      );
      this.max_value = true;

      e.target.value -= this.totalCardAmount - this.charityAmount();
      this.selectedProducts[i].amount = Number(e.target.value);
      this.singleSelectedProducts[i].amount = Number(e.target.value);
    } else {
      this.max_value = false;
    }
  }

  get totalCardAmount() {
    return this.selectedProducts.reduce((pv, cv) => cv.amount + pv, 0);
  }

  chooseByRetailer(viewOnly: boolean, multiple?: boolean, i?: number) {
    this._appService.matDialog
      .open(CreateCatalogueProductAndProceedComponent, {
        maxHeight: '100vh',
        maxWidth: '100vw',
        width: '90vw',
        height: '90vh',
        data: {
          data: {
            giftAmount: this.isMultiple
              ? this.selectedProducts[i]?.amount
              : this.singleSelectedProducts[0]?.amount,
          },
          isSingleToProceed: !multiple,
          campaign: {
            ...this.data?.campaign,
            ...this.data?.data.this.form?.value,
            cardAmount: this.data?.data.this?.campaignCardPr,
            charityAmount:
              this.data?.data.this?.campaignCardPr -
              this.data?.campaign.collectedAmount,
          },
          remainingAmount: this.data.campaign.remainingAmount,
          viewOnly,
          multiple,
          charityAmount: this.charityAmount(),
        },
      })
      .afterClosed()
      .subscribe((d) => {
        if (i != null) {
          this.selectedProducts[i].item = d.data;
          this.selectedProducts[i].amount = this.selectedProducts[i]?.amount;
        }
        if (!this.isMultiple) {
          this.singleSelectedProducts[0].item = d.data;
          this.singleSelectedProducts[0].amount =
            this.singleSelectedProducts[0]?.amount;
        }
        this.selectedProduct = d.data;

        if (d.isSingleToProceed) {
          if (d) {
            this.data.campaign = { ...this.data.campaign, ...d };
            this.data.campaign.isAmountProcessed = true;
            this.campaignChange.emit(this.data.campaign);
            // this.createProdigiOrder();
            // Object.keys(this.form.controls).forEach((d) =>
            //   this.form.get(d).disable()
            // );
            this.successDialog();
          }
          this.dialogRef.close();
        }
      });
  }

  // createProdigiOrder() {
  //   if (this.form.get('cardType').value === 'prodigi') {
  //     this.prodigiForm.createOrder();
  //   }
  // }

  successDialog() {
    const sendToRecipient = `Thank you for redeeming your CollectaGift Pot for ${this.data?.campaign.receipientName}. The selected Gift Voucher(s), together with the Greeting Card, will be emailed to the Recipient at the email address provided. If you scheduled send, they will be sent at the requested time, otherwise they will be sent straight away. Thank you for using CollectaGift and if you have any questions, please contact us on hello@collectagift.com.`;
    const sendToMySelf = `Thank you for redeeming your CollectaGift Pot for ${this.data?.campaign.receipientName}. The selected Gift Voucher(s) will be emailed to your registered email address. The Greeting Card will be sent directly to the Recipient at the email address provided.  If you scheduled send, the Greeting Card will be sent at the requested time, otherwise it will be sent immediately. Thank you for using CollectaGift and if you have any questions, please contact us on hello@collectagift.com.`;
    const sendToMySelfManual = `Thank you for redeeming your CollectaGift Pot for ${this.data?.campaign.receipientName}.  The selected Gift Voucher(s) will be emailed to your registered email address for you to send to the Recipient when you wish or to buy the Recipient a Gift using the voucher. The Greeting Card will be also be sent to your registered email address and you can forward it onto the Recipient whenever you wish.`;

    this._matDialog
      .open(SuccessMessageDialogComponent, {
        data: {
          title: this.data.campaign.sendToRecipientEmail
            ? sendToRecipient
            : this.data.campaign.isAutoEmailToRecipient
            ? sendToMySelf
            : sendToMySelfManual,
        },
      })
      .afterClosed()
      .subscribe((d) => {
        this._router.navigate(['/pages/detail/campaigns']);
      });
  }

  get isSelectedProducts() {
    return this.selectedProducts.some((product) => product.item !== null);
  }

  async done() {
    this.isLoading = true;
    let body = {
      id: this.data.campaign.id,
      purchaseCard: true,
      charityAmount:
        this.data.campaign.collectedAmount - this.data.campaign.cardAmount,
      cardAmount: this.data.campaign.cardAmount,
      comment: this.data.campaign.comment,
      userAmount: this.data.campaign.userAmount,
      cardRecipientEmail: this.data.campaign.cardRecipientEmail,
      campaignCurrency: this.data.campaign.campaignCurrency,
      charityId: this.data.campaign.charityId,
      giftAmount: null,
      automaticCardEmail: this.data.campaign.automaticCardEmail,
      ...(this.data.data.sendType && {
        cardEmailTime: this.data.campaign.cardEmailTime,
      }),
      receipientEmail: this.data.campaign.cardRecipientEmail,
      stripeCharges: this.data.campaign.stripeCharges,
      templateType: this.data.campaign.templateType,
      selectedTemplateUrl: this.data.campaign.selectedTemplateUrl,
      isAmountProcessed: this.data.campaign.isAmountProcessed,
      productId: null,
      giftCardList: [],
      isDonated: this.data.isDonated,
      sendToRecipientEmail: this.data.campaign.sendToRecipientEmail,
      ...(!this.data.campaign.sendToRecipientEmail && {
        isAutoEmailToRecipient: this.data.campaign.isAutoEmailToRecipient,
      }),
    };
    if (this.campaign.templateType == 'freeCard') {
      body.cardAmount = 0;
      body.giftAmount = this.data.campaign.remainingAmount;
    }
    body.charityAmount =
      this.data.campaign.collectedAmount - this.data.campaign.cardAmount;
    body.receipientEmail = this.data.campaign.cardRecipientEmail;

    if (this.isMultiple) {
      const amount = this.selectedProducts.map((list) => list?.amount);
      const products = this.selectedProducts.map(
        (list) => list?.item?.items[0]?.code
      );
      body.giftAmount = this._campaignService.removeEmptyGiftCards(amount);
      body.productId = this._campaignService.removeEmptyGiftCards(products);
    } else {
      body.giftAmount = this.singleSelectedProducts[0].amount;
      body.productId = this.singleSelectedProducts[0].item.items[0].code;
    }

    const payload = this._campaignService.removeEmptyKeysValues(body);
    const data = await this._campaignService.RedeemDonate(payload);
    // const data = '';
    if (!data) {
      return (this.isLoading = false);
    }
    this.isLoading = false;
    const isSingleToProceed = true;
    this.dialogRef.close({ data, isSingleToProceed });
    this.successDialog();
  }

  charityAmount() {
    return (
      this.data?.campaign?.collectedAmount -
      this?.data.data?.this.campaignCardPr
    );
  }

  get remainingAmountLeft() {
    const remaining = -this.totalCardAmount + this.charityAmount();
    return remaining.toFixed(2);
  }
}
